import * as actionTypes from "./actionType";
import { updateObject } from "../utility";

export const initialState = {
  error: null,
  loading: null,
  piqvideofeed: {},
  piqvideofeedDetail: null,
  videoFeed: {
    id: "3",
    title: "ABC News Australia ",
    url: "https://www.youtube.com/watch?v=fliqxdjdWsc",
    description: "",
  },
};

const piqvideofeedGetStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const piqvideofeedGetSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    piqvideofeed: action.piqvideofeed,
  });
};
const piqvideofeedGetFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const piqvideofeedDetailStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const piqvideofeedDetailSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    piqvideofeedDetail: action.piqvideofeedDetail,
  });
};
const piqvideofeedDetailFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const setpiqvideofeed = (state: any, action: any) => {
  return updateObject(state, {
    videoFeed: action.videoFeed,
  });
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.PIQVIDEOFEED_GET_START:
      return piqvideofeedGetStart(state, action);
    case actionTypes.PIQVIDEOFEED_GET_SUCCESS:
      return piqvideofeedGetSuccess(state, action);
    case actionTypes.PIQVIDEOFEED_GET_FAIL:
      return piqvideofeedGetFail(state, action);

    case actionTypes.PIQVIDEOFEED_DETAIL_START:
      return piqvideofeedDetailStart(state, action);
    case actionTypes.PIQVIDEOFEED_DETAIL_SUCCESS:
      return piqvideofeedDetailSuccess(state, action);
    case actionTypes.PIQVIDEOFEED_DETAIL_FAIL:
      return piqvideofeedDetailFail(state, action);

    case actionTypes.SET_PIQ_VIDEO_FEED:
      return setpiqvideofeed(state, action);

    default:
      return state;
  }
};
export default reducer;
