import React, { Suspense, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

import { runSocketConnect, socket as socketInstance } from "src/Socket";
import { setWindowSize } from "src/store/PIQ/action";
import { openNewWindow } from "src/utils/common";
import { useAuthContext } from "src/context/AuthProvider";
import { useTrackerContext } from "./context/TrackerProvider";
import { useLayoutContext } from "./context/LayoutProvider";
import { activeDrawer } from "./interface/PiqState";
import { editArticleListConfig } from "./store/User/action";
import { fetchpiqcolumnsData } from "./store/PiQColumns";
import { fetchInFeedAdsData } from "./store/InFeedAds";
import { isLocalhost } from "./serviceWorker";
import { initGA, trackingPageGA } from "./development/Component/googleAnalytics4";

import { RTM } from "./development/Component/RTM";
import MetaTags from "./MetaTags";
import { BookmarkProvider } from "./context/BookmarkProvider";

import piqLogo from "src/style/images/piq-logo-v2.svg";
import PagesRoute from "./development/Component/AppLayout/PagesRoute";

function App() {
  const dispatch = useDispatch();
  const { setActiveDrawer } = useLayoutContext();
  const { user, isSignedIn }: any = useAuthContext();
  const [isWindowLoaded, setIsWindowLoaded] = useState(false);
  const { handleDrawerOpen } = useTrackerContext();
  const { darkMode } = useSelector((state: any) => ({ darkMode: state?.user?.darkMode }));

  const handleActiveDrawerChange = useCallback((activeDrawer: activeDrawer) => {
    handleDrawerOpen(activeDrawer);
    setActiveDrawer(activeDrawer);
  }, [handleDrawerOpen, setActiveDrawer]);

  useEffect(() => {
    dispatch(fetchpiqcolumnsData());
    dispatch(fetchInFeedAdsData());
    trackingPageGA(window.location.pathname + window.location.search);
    initGA();
    dispatch(setWindowSize([window.innerWidth, window.innerHeight]));
    setIsWindowLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window?.innerWidth <= 700) {
        handleActiveDrawerChange("article-list-drawer");
      }
      dispatch(setWindowSize([window.innerWidth, window.innerHeight]));
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [dispatch, handleActiveDrawerChange]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const openNewWindowHref = (event.target as HTMLElement)?.getAttribute("openNewWindowHref");
      if (openNewWindowHref) openNewWindow(openNewWindowHref);
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    runSocketConnect(isSignedIn ? user?.signInUserSession?.accessToken?.jwtToken : "");
  }, [isSignedIn, user?.signInUserSession?.accessToken?.jwtToken]);

  useEffect(() => {
    dispatch(editArticleListConfig({ tabKey: "reuters", localPage: 1 }));
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    Auth.currentAuthenticatedUser();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(editArticleListConfig({ tabKey: "reuters", localPage: 1 }));
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [dispatch]);

  useEffect(() => {
    const onData = (data: any) => {
      notification.open({
        message: "",
        key: data?._id,
        description: <RTM data={data} dispatch={dispatch} notification={notification} />,
        duration: 0,
        style: {
          backgroundColor: "#EE5E49",
          width: 500,
          padding: "15px",
          paddingTop: "10px",
        },
      });
    };

    socketInstance.on("rtm", onData);
    return () => socketInstance.off("rtm", onData);
  }, [dispatch]);

  useEffect(() => {
    if (!isLocalhost) {
      Sentry.init({
        dsn: "https://2e30ba8c60453be8112e9549a3cf1272@sentry.corp.black/18",
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: [
              "localhost",
              "https://api.priapusiq.com",
              "https://piqsuite.com/",
            ],
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    const fpr = function () {
      (window as any).fpr.q = (window as any).fpr.q || [];
      (window as any).fpr.q.push(arguments);
    };
    (window as any).fpr = (window as any).fpr || fpr;
    (window as any).fpr("init", { cid: "dyafflt1" });
    (window as any).fpr("click");

    const script = document.createElement("script");
    script.src = "https://cdn.firstpromoter.com/fpr.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      console.log = console.error = console.debug = () => { };
    }
  }, []);

  if (!isWindowLoaded) return null;

  if (typeof window !== "undefined" && window?.innerWidth < 750) {
    window.location.href = `https://m.piqsuite.com/${window.location.pathname}`;
    return null;
  }

  return (
    <Suspense fallback={<div className="suspension-div"><img src={piqLogo} alt="" /></div>}>
      <MetaTags
        title="PiQ Suite: News & Data for Traders"
        description="PiQ Suite aggregates news, data, social media feeds, and apps from over 100 sources. Try PiQ Suite for free today."
      />
      <main>
        <GoogleOAuthProvider clientId="682542158674-5bug0qulqf447mevr2bdb66pq9c5iehv.apps.googleusercontent.com">
          <BookmarkProvider>
            <PagesRoute />
          </BookmarkProvider>
        </GoogleOAuthProvider>
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: "1rem",
              background: darkMode ? "black" : "white",
              color: darkMode ? "white" : "black",
            },
          }}
        />
      </main>
    </Suspense>
  );
}

export default App;
