const config = {
  // PROD_URL: "https://api.priapusiq.com",
  // PROD_URL: "https://testapi.priapusiq.com",
  PROD_URL: process.env.REACT_APP_API_URL,
  MAIN_URL: process.env.REACT_APP_MAIN_API_URL,
  TEST_URL: process.env.REACT_APP_TEST_API_URL,
  FEED_URL: process.env.REACT_APP_FEED_URL,
  PIQ_VERSION: 2.0,
};

export default config;
