import { useSelector } from "react-redux";
import useFeeds from "./useFeeds";
import { useAuthContext } from "src/context/AuthProvider";
import { openNewWindow } from "src/utils/common";
import { useLocationContext } from "src/context/LocationProvider";
import { useEffect, useState } from "react";
import { useLayoutContext } from "src/context/LayoutProvider";

const useInFeedAds = () => {
    const AD_INTERVEL = 7
    const chunkSize = 7;
    const { getColumnDetail }: any = useFeeds();
    const { isSignedIn: isUserLoggedIn } = useAuthContext();
    const InfeedAdsData = useSelector((state: any) => {
        return state?.infeedads?.data;
    });
    const { setActiveDrawer } = useLayoutContext()
    const [ColumnAds, SetColumnAds] = useState(InfeedAdsData || [])

    const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
        hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false,
    }));
    const { isUS } = useLocationContext()
    useEffect(() => {
        if (isUS) {
            const withOutPepperstone = InfeedAdsData.filter((item: any) => (item?.type !== "PEPPERSTONE"))
            SetColumnAds(withOutPepperstone)
        } else {
            SetColumnAds(InfeedAdsData)
        }
    }, [isUS, InfeedAdsData])
    const getColumnAdsDetail = (slug: string) => {
        const column = getColumnDetail(slug);
        if (column) {
            if (column?.has_ads) {
                let ads_for_this_column = [];
                for (let i = 0; i <= ColumnAds?.length; i++) {
                    const item = ColumnAds[i];
                    if (item?.visible_columns?.includes(column?._id)) {
                        ads_for_this_column.push(item);
                    }
                }

                return ads_for_this_column;
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const getColumnAds = (slug: string) => {
        const ads = getColumnAdsDetail(slug);
        return ads?.filter((item: any) => {
            if (isUserLoggedIn && hasSubscriptionForAdsRemoval) {
                return item?.visible_to_free_iq && item?.visible_to_high_iq;
            } else if (isUserLoggedIn) {
                return item?.visible_to_free_iq;
            } else {
                return item?.visible_to_unregistered_users;
            }
        });
    };

    const getAllAds = () => {
        return ColumnAds?.filter((item: any) => {
            if (isUserLoggedIn && hasSubscriptionForAdsRemoval) {
                return item?.visible_to_free_iq && item?.visible_to_high_iq;
            } else if (isUserLoggedIn) {
                return item?.visible_to_free_iq;
            } else {
                return item?.visible_to_unregistered_users;
            }
        });
    };

    function pushAdsToColumn(newData: any, slug: string, data: any, adsCoordinates: any, setAdsCoordinates: any, is_custom_feed: boolean = false) {

        let column_ads: any
        if (is_custom_feed) {
            column_ads = getAllAds();
        } else {
            column_ads = getColumnAds(slug);
        }

        if (!column_ads?.length) return newData
        if (!newData?.data?.length) { return }
        let data_with_ads;

        let ads_config_in_local_storage: any = JSON.parse(localStorage.getItem(`piq_ads_config`) || "{}")
        if (ads_config_in_local_storage[slug]) {

            if (ads_config_in_local_storage?.[slug]?.['ads_count'] < 2) {
                const ads_local_config = ads_config_in_local_storage[slug]
                ads_local_config['ads_count'] = ads_local_config['ads_count'] + 1
                ads_local_config['last_item'] = newData?.data[0]

                ads_config_in_local_storage[slug] = ads_local_config
                data_with_ads = newData
                localStorage.setItem(`piq_ads_config`, JSON.stringify(ads_config_in_local_storage))
            } else {

                const ads_local_config: any = {
                    ads_count: 0,
                    last_item: newData?.data[0],
                }
                if (ads_config_in_local_storage[slug]['last_ad_index'] < column_ads?.length - 1) {
                    ads_local_config['last_ad_index'] = ads_config_in_local_storage[slug]['last_ad_index'] + 1;
                } else {
                    ads_local_config['last_ad_index'] = 0;  // Reset if index is out of bounds
                }
                ads_config_in_local_storage[slug] = ads_local_config
                data_with_ads = {
                    ...newData,
                    data: [...newData?.data, { ...column_ads[ads_local_config['last_ad_index']], isAd: true }]
                }
                localStorage.setItem(`piq_ads_config`, JSON.stringify(ads_config_in_local_storage))

            }


        } else {
            let ads_local_config: any = {
                ads_count: 0,
                last_item: newData?.data[0],
                last_ad_index: 0
            }
            ads_config_in_local_storage[slug] = ads_local_config
            data_with_ads = {
                ...newData,
                data: [...newData?.data, { ...column_ads[ads_local_config['last_ad_index']], isAd: true }]
            }
            localStorage.setItem(`piq_ads_config`, JSON.stringify(ads_config_in_local_storage))

        }








        // if (!adsCoordinates?.length) {
        //     data_with_ads = {
        //         ...newData,
        //         data: [{ ...column_ads[0], list_item_type: "IN_FEED_ADS" }, ...newData?.data]
        //     }
        //     setAdsCoordinates([{ previous_data_id: null, ads_id: column_ads[0]?._id, next_data_id: data_with_ads?.data[1]?._id, ads_in_data_index: 0 }])
        // } else {
        //     const last_ads_coordinate = adsCoordinates[adsCoordinates?.length - 1]

        //     let last_ad_index_in_data = data?.findIndex((item: any) => item?._id === last_ads_coordinate?.ads_id);

        //     if (last_ad_index_in_data !== -1 && last_ad_index_in_data % 3 === 0 && last_ad_index_in_data !== 0) {
        //         const last_ad_coordinate_in_ads_column_index = column_ads?.findIndex(item => item?._id === last_ads_coordinate?.ads_id);
        //         let new_ads_to_add;

        //         const last_col_ad = data[last_ad_index_in_data]

        //         if (last_col_ad?._id !== column_ads[column_ads?.length - 1]?._id) {

        //             new_ads_to_add = { ...column_ads[last_ad_coordinate_in_ads_column_index + 1], list_item_type: "IN_FEED_ADS" }

        //         } else {
        //             new_ads_to_add = { ...column_ads[0], list_item_type: "IN_FEED_ADS" }


        //         }
        //         data_with_ads = {
        //             ...newData,
        //             data: [new_ads_to_add, ...newData?.data]
        //         }

        //         setAdsCoordinates([{ previous_data_id: null, ads_id: new_ads_to_add?._id, next_data_id: data_with_ads?.data[1]?._id, ads_in_data_index: 0 }])
        //     } else {
        //         data_with_ads = {
        //             ...newData,
        //             data: [...newData?.data]
        //         }
        //     }
        // }
        return data_with_ads

    }

    function splitArrayIntoChunks(array: any[], slug: string) {
        const column_ads = getColumnAds(slug);
        let chunks = [];
        let adIndex = 0;
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
            // Add the corresponding ad after the chunk if there are remaining ads
            if (adIndex < column_ads.length && i !== array.length - 1) {
                chunks.push([
                    { ...column_ads[adIndex], list_item_type: "IN_FEED_ADS" },
                ]);
                adIndex++;
                if (adIndex >= column_ads.length) {
                    adIndex = 0;
                }
            }
        }
        return chunks;
    }

    const handleAdsClick = (item: any) => {
        if (item?.internal_link_action && item?.internal_link_action !== "") {
            setActiveDrawer(item?.internal_link_action);
        } else {
            openNewWindow(item?.redirect_url);
        }
    };

    return {
        ColumnAds,
        getColumnAdsDetail,
        getColumnAds,
        splitArrayIntoChunks,
        handleAdsClick,
        pushAdsToColumn,
    };
};

export default useInFeedAds;
