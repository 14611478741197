import React, { useState } from "react";
import { useSelector } from "react-redux";
import searchIcon from "src/style/images/global_search_icon.svg";
import focusedSearchLight from "src/style/images/fucused_search_light.svg";
import focusedSearch from "src/style/images/focused_search.svg";

function FeedSearch({ handleSearch, searchedText, showSearch }: any) {
  const [isFocused, setIsFocused] = useState(false);
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div
      className="search_feed_container"
      hidden={!showSearch}
    >
      <div
        // className={darkMode ? "dark_searchbox_feed" : "searchbox_feed"}
        className={`searchbox_feed ${darkMode ? "dark_searchbox_feed" : ""}`}

      // style={{ width: "98%" }}
      >
        <div className="search_box">
          <div className="group">
            {!isFocused ? (
              <img
                className="icon"
                src={searchIcon}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            ) : darkMode ? (
              <img
                className="icon"
                src={focusedSearch}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            ) : (
              <img
                className="icon"
                src={focusedSearchLight}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            )}
            <input
              placeholder="Search"
              type="search"
              className="input"
              onChange={handleSearch}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={searchedText}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedSearch;
