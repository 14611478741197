import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useState } from "react";

import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import lockIcon from "src/style/images/lock-blue.svg";
import { loadICDashboardColumnFromApi } from "src/store/PIQ/action";
import { useDispatch } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import { SocketNames } from "src/interface/PiqState";
import ColumnLoading from "src/development/Component/Loading";
import ThirdPartyApp from "src/development/Component/AppSelector/ThridPartyApp";
import { useSelector } from "react-redux";
import { useLayoutContext } from "src/context/LayoutProvider";

export const CompleteIntelComponent: React.FC<{
  socket: SocketNames;
}> = ({ socket }) => {
  const dispatch = useDispatch();
  const { isSignedIn: isUserLoggedIn, user: userDetials } = useAuthContext();
  const { setActiveDrawer, activeDrawerState } = useLayoutContext()

  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const { piqSuiteLayout, setPiqSuiteLayout } = useLayoutContext()

  const [layoutData, setLayoutData] = useState<any>(
    piqSuiteLayout.find((data: any) => data.i === socket)
  );
  const [piqSendEmail, setPiqSendEmail] = useState(false);

  const [url, setUrl] = useState<string>("");
  const refresh_function = () => {
    setFetching(true);
    const time = setTimeout(() => {
      setFetching(false);
    }, 1000);
    return () => clearTimeout(time);
  };
  useEffect(() => {
    const user: any = userDetials;
    if (isUserLoggedIn && user) {
      loadICDashboardColumnFromApi({
        email: user?.attributes?.email,
        piqSendEmail: piqSendEmail,
      })
        .then((res: any) => {
          const { data } = res;
          if (data?.url) {
            setUrl(data?.url);
          }
        })
        .catch((err: any) => {
          console.log("err", Object.keys(err.response.data));
          // removeColumn(socket);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, userDetials, socket, dispatch]);

  const darkMode = useSelector((state: any) => state?.user?.darkMode);

  return (
    <>
      <div className={`view-chart-div `}>
        <ColumnHeader
          socket={socket}
          isFetching={isFetching}
          fetchFunction={() => refresh_function()}
          title={
            !layoutData?.completeIntegilence
              ? "Complete Intelligence"
              : "Complete Intel"
          }
          handleEditPanel={() => setShowEditPanel(true)}
        />
        {showEditPanel && socket ? (
          <ColumnHeaderOption
            setShowEditPanel={setShowEditPanel}
            socket={socket}
          />
        ) : (
          <>
            {!layoutData?.completeIntegilence ? (
              <div
                style={{
                  marginLeft: 4,
                  backgroundColor: darkMode ? "#384251" : "white",
                  height: "100%",
                  padding: 10,
                }}
                key={isUserLoggedIn ? 1 : 2}
              >
                <ThirdPartyApp
                  socket={socket}
                  setLayoutData={setLayoutData}
                  piqSendEmail={piqSendEmail}
                  setPiqSendEmail={setPiqSendEmail}
                />
              </div>
            ) : (
              <>
                {!isFetching ? (
                  <div
                    className={`view-chart ${!isUserLoggedIn ? "blur-effect" : ""
                      }`}
                    key={isUserLoggedIn ? 1 : 2}
                  >
                    <iframe
                      height={"100%"}
                      width={"100%"}
                      title="Complete Intel"
                      src={
                        // "https://cimarkets.completeintel.com/api/auth/piq?uuid=6e88c1e9-9e40-42f4-a662-36397aad2777&session=MjdlY2YxZWRiZDM1YjFmMjlhMDA5MzkyODkxZGExYmY1YjA0M2M2MTljMmJiZDIwOGYyMDE3M2Y2YWNhYTRlMGYxNjQ2OGRlZjg5NTE3NWVhMjFiNzM1Yjk5Y2IzMTY2ZTMwNGI0MmUyMzFkMzYxOTE5ODY4YmCwMGE5NmQxNjkyMmQ3ZjY1NmEwMWE0YjlmNTc5ZDcxM2NhMzVhOGQyNDZkMjkxYWVjMzE4NzBkYzQwYzI5MGFiZDM5NjBhMWRhYjc0ZmEzYWFlYzY2Mzg3MTcxNjg0NmNlNGIxMDEwY2MwYjk4OTE4ODM1ODMzNjQzMWY4MjY2YWIxZGQ4MDQ2MWIzY2FkYTc3ZDM5MjNjOGZmMWM0YmC4MmY3OGFlNzhjM2E1NjQ1ZmZjMzJlY2JiZDc1M2I2MWU0MWYxNTRjNTQxNjlmMjRkMmEwMmNmYjA1NDFjNTViOTA4OGI0MjA5NzQzYWFmYzE0ODIxMDE2MjMwOTc3ZGRlMTUyM2E5YzIyYTJiNzcxYmRlMzFhNjJmMzgyZDYyOTlmY2E2ZjViZmU3NDY0ZGJkNTMxYTVjOTc4YmUwMzZlZDJhZjVhZTM3Mzc3ZjkyOWNmYTlmNDUxMzRiNThkYjZiNDFiZmMzMWVmOTQ1ZmI2MjJiNDZlYjAxNTAyOTc4NjVmOWY3ZmI3Nzc1ZDI2YzZjZTJmMDdmYzdlM2E5MWVlYzRmYWFkMGZjYTBkM2JlYjY0OGMxMGU2YzQzMzBlOWQzNTMwNDM0M2FkMzNmYzk1OWVmNDk1NWZhZTIxNzc3NDFiM2Y3YmVlMjhkN2IxMDEyMmE2N2Q2ZTE0ZTU3N2JlMGMyZmM4MjU0YzY1YjI3YjIyYmExNTQ0YTZmY2ZmNmIyNzJkNGJjY2NmZjc2YTZjNTNmYzhhNTA5ZmI3MGYyMmM5OTBjMjFmN2Q="
                        url
                      }
                    />
                  </div>
                ) : (
                  <ColumnLoading />
                )}
              </>
            )}
          </>
        )}
      </div>
      {!isUserLoggedIn ? (
        <>
          <div
            className="overlay-blur"
            onClick={() => setActiveDrawer("upgrade-panel-drawer")}
          ></div>
          <img src={lockIcon} alt="" className="lock-icon" />
        </>
      ) : (
        ""
      )}

      {/* <SignIn
        openLoginDrawer={openLogin}
        closeLoginDrawer={(open: boolean) => setOpenLogin(open)}
      /> */}
    </>
  );
};
