import React from "react";
import { useSelector } from "react-redux";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useState } from "react";

import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import { useAuthContext } from "src/context/AuthProvider";
import { SocketNames } from "src/interface/PiqState";
import { InvestingEconomicCalenderData } from "src/SuiteConfig/InvestingEconomicCalender";
import ColumnLoading from "src/development/Component/Loading";
import useFeeds from "src/Hooks/useFeeds";

export const InvestingEconomicCalenderComponent: React.FC<{
  socket: SocketNames;
}> = ({ socket }) => {
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const [isFetching, setFetching] = useState<boolean>(false);
  const { getColumnDetail } = useFeeds()
  const column = getColumnDetail(socket)
  const refresh_function = () => {
    setFetching(true);
    const time = setTimeout(() => {
      setFetching(false);
    }, 1000);
    return () => clearTimeout(time);
  };

  return (
    <>
      <div className={`view-chart-div `}>
        <ColumnHeader
          socket={socket}
          isFetching={isFetching}
          fetchFunction={() => refresh_function()}
          title={column?.title}
          handleEditPanel={() => setShowEditPanel(true)}
        />
        {showEditPanel && socket ? (
          <ColumnHeaderOption
            setShowEditPanel={setShowEditPanel}
            socket={socket}
          />
        ) : (
          <>
            <div
              className={
                `calender-chart`
                //  ${!isUserLoggedIn ? "blur-effect" : ""}
              }
              // style={{ marginLeft: 4 }}
              key={isUserLoggedIn ? 1 : 2}
            // hidden={!isUserLoggedIn}
            >
              {isFetching ? (
                <ColumnLoading />
              ) : (
                <iframe
                  height={"100%"}
                  width={"100%"}
                  title="Investing Economic Calender"
                  src={
                    darkMode
                      ? InvestingEconomicCalenderData.dark
                      : InvestingEconomicCalenderData.light
                  }
                // "https://awsdev.completeintel.com/api/auth/piq?uuid=1fa32d3b-0f2b-439e-afcb-b8cc90dde8ff&session=OTQzNzUwNTZiOGZmM2Q3YTk3MWMyMDAxYzJjNmUzMzI1ZDI4NzQ4ODBmYzQzODhjYTdlNjExYTE1N2U5ODVlNGFjZGEyYzZhOTAyZGZiMzY2ZGU2ZjUzZTE2YmQ2Zjk4NzcyNDg3NzhiZTExZjUzMmEyNmEwMDdiNTI4NjMyMDk2Yzk4MGFkMjhjNjYxMjFiNGQzM2Q1YzUzYWY3OWE2NTI2ZTljMmZjM2YyNDhmNWE4ODU0NWE2YTFiZDgwZWZkNGZhMmM3MzljNGVmZGE5ZTY4MDgxZTkwM2FhZGUxZjgwMDczYjQ4MzhhMTgxNmI0NWJhNmE1NzQ2MmQ3ZWEwZTE3MTJiYWI5YjdhOTg3ODdlZTkwZjkyNDYyNmNkMWRjNTBjOWE5OTkxMzFiMzYzZTY0MDBhYjJkNTZlNTUzNzkwM2I4MDE0OTJkYjdmZTIyMWZiMzFkMmNlOTkzOWNkNjU4YmFiYmQ3OTBkOTZhYzlmNjc2YTkxMzk0YzJjOWJlZWI1YjUxODQyYWU2ZDYyOTA2MDNhZTg5ODkwNTBkZDQ4ZTg0ODU0M2U5NDViYjdmYTFjZWEzNjI4NmEwNWRiNTU5M2NhYzBjMDAxY2NmNTg3ZmFkZTkxYTY1NjI0NzAyY2MxYWI1OGE4ZDNiZjVjM2JkOWYyZmJjNzFkZWUwMjYxOGUzNDE0NDc5MThlNmRjOWRlODMxMzY2MTQ5YzU1NzgyOTI5ZTk0ZGY4NGMwMDdlNmU0YTUyYzgyY2Y3M2U4NjIxZjQxNzRjNDdjNTBkZTZiODczNzUyMjJjNTYxYmZkODBmMDg1OTBlMDljYjg2YTgyMTJmOGVhOTc5ZWNjMTJlMDhiYzgyYmZhZjgzMjNkNzM3M2RjZmRiZTUyMjNkY2I4MTdjYjg4MGM0OTI2ZGFlZWQ3NzI3MmI4ZmU2NDI="
                />
              )}
            </div>
            <div className="investing-credit">
              Real Time Economic Calendar provided by{" "}
              <a
                href="https://www.investing.com/"
                rel="noreferrer"
                target="_blank"
                className="underline_link"
              >
                Investing.com
              </a>
            </div>
          </>
        )}
        {/* {!isUserLoggedIn ? (
          <>
            <div
              className="overlay-blur"
              onClick={() => setActiveDrawer("upgrade-panel-drawer")}
            >
              <div>
                <img
                  src={TradingViewLogo}
                  alt=""
                  className="lock-log"
                  style={{
                    height: 50,
                    width: 50,
                    marginTop: 50,
                    marginLeft: 45,
                  }}
                />
              </div>
              <div className="lock-text">
                add Investing.com Economic Calendar
                <div>
                  to your <span>PiQ Suite</span> Dashboard
                </div>
              </div>
              <div>
                <img src={lockIcon} alt="" className="lock-icon" />
              </div>
              <div className="lock-signup">
                <span
                  onClick={() => setActiveDrawer("upgrade-panel-drawer")}
                  className="signup-text"
                >
                  Sign up
                </span>{" "}
                for <span>FREE</span> to unlock
              </div>
            </div>
          </>
        ) : (
          ""
        )} */}
      </div>

      {/* <SignIn
        openLoginDrawer={openLogin}
        closeLoginDrawer={(open: boolean) => setOpenLogin(open)}
      /> */}
    </>
  );
};
