import React, { createContext, useContext, useEffect } from "react";
import { DeviceInfoContext } from "./DeviceInfoProvider";
import mixpanel from 'mixpanel-browser';
import { trackEvents } from "src/constants/tracker";
import { activeDrawer } from "src/interface/PiqState";
import { useAuthContext } from "./AuthProvider";
import { ITrackerContext, sendEventParams } from "src/interface/tracker";
import { useSelector } from "react-redux";


export const TrackerContext = createContext<ITrackerContext>({
    sendEvent: () => { },
    handleDrawerOpen: () => { },
    handleDrawerClose: () => { },
    trackSignUpSuccess: () => { },
    sendActiveFeedDuration: () => { },
    trackLoginSuccess: () => { },
    resetMixpanel: () => { }
})

export const useTrackerContext = () => useContext(TrackerContext)

export const TrackerProvider: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {

    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY as string, {
        // debug: true,
        track_pageview: true,
        persistence: 'localStorage'
    });

    const originalTime = new Date().getTime()
    const deviceInfo = useContext(DeviceInfoContext);
    const userDetail = useSelector((state: any) => state?.user?.userDetail)

    useEffect(() => {
        if (userDetail && mixpanel) {
            try {
                mixpanel?.identify(userDetail?._id);
                mixpanel?.people?.set_once({
                    $first_name: userDetail?.given_name,
                    $last_name: userDetail?.family_name,
                    $email: userDetail?.email,
                    $user_id: userDetail?.user_id,
                });
            } catch (err) { }
        }
    }, [userDetail, mixpanel]);

    const getEventName = ({ eventName, payload }: sendEventParams) => {
        switch (eventName) {
            case "INPUT_FIELD_COMPLETE":
                return payload?.type === "login" ? `S_I_${payload?.fieldName?.toUpperCase()?.replace(/ /g, "_")}` : `S_U_${payload?.fieldName?.toUpperCase()?.replace(/ /g, "_")}`;

            default:
                return eventName
        }
    }

    const sendActiveFeedDuration = async ({
        feedInitTime,
        payload
    }: {
        feedInitTime: number,
        payload: any
    }) => {
        const currentTime = new Date().getTime();
        const difference = currentTime - feedInitTime;
        const seconds = Math.floor((difference / 1000) % 60);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));

        const readableDifference = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

        sendEvent({
            eventName: trackEvents.FEED_SESSION_TIME,
            payload: {
                ...payload,
                duration: readableDifference
            }
        })
    }

    const cleanPayload = (payload: any) => {
        if (payload?.type) delete payload.type
        if (payload?.fieldName) delete payload.fieldName;

        return payload
    }

    const sendEvent = ({
        eventName,
        payload
    }: sendEventParams) => {
        const event: string = getEventName({ eventName, payload });
        const cleanedPayload = cleanPayload(payload)
        try {
            mixpanel?.track(event, cleanedPayload)
        } catch (err) {
        }
    }

    const handleDrawerOpen = (activeDrawer: activeDrawer) => {
        if (activeDrawer === 'signin-drawer') {
            sendEvent({
                eventName: trackEvents.SIGN_IN_DRAWER_OPEN
            })
        } else if (activeDrawer === 'signup-drawer') {
            sendEvent({
                eventName: trackEvents.REGISTER_DRAWER_OPEN
            })
        } else if (activeDrawer === 'forgot-password-drawer') {
            sendEvent({
                eventName: trackEvents.RESET_PASSWORD_DRAWER_OPEN
            })
        }
    }

    const handleDrawerClose = (activeDrawer: activeDrawer) => {
        if (activeDrawer === 'signin-drawer') {
            sendEvent({
                eventName: trackEvents.SIGN_IN_DRAWER_CLOSED
            })
        } else if (activeDrawer === 'signup-drawer') {
            sendEvent({
                eventName: trackEvents.REGISTER_DRAWER_CLOSED
            })
        } else if (activeDrawer === 'forgot-password-drawer') {
            sendEvent({
                eventName: trackEvents.RESET_PASSWORD_DRAWER_CLOSED
            })
        }
    }

    const trackSignUpSuccess = async (data: any) => {
        sendEvent({
            eventName: trackEvents.SIGNUP_SUCCESS,
            payload: {
                first_name: data?.first_name,
                last_name: data?.last_name,
                email: data?.email,
                user_id: data?.user_id
            }
        })
        try {
            mixpanel?.alias(data?.user_id);
            mixpanel?.people?.set({
                $first_name: data?.first_name,
                $last_name: data?.last_name,
                $email: data?.email,
                $user_id: data?.user_id,
                $created: new Date().toISOString(),
            });
        } catch (err) {

        }
    }

    const trackLoginSuccess = async (data: any) => {
        sendEvent({
            eventName: trackEvents.LOGIN_SUCCESS,
        })
        try {
            mixpanel?.identify(data?.user_id)
            mixpanel?.people?.set_once({
                $first_name: data?.first_name,
                $last_name: data?.last_name,
                $email: data?.email,
                $user_id: data?.user_id,
            });
        } catch (err) {

        }
    }

    const resetMixpanel = () => {
        try {
            mixpanel?.reset()
        } catch (err) {

        }
    }


    return (
        <TrackerContext.Provider
            value={{
                sendEvent,
                handleDrawerOpen,
                handleDrawerClose,
                trackSignUpSuccess,
                sendActiveFeedDuration,
                trackLoginSuccess,
                resetMixpanel
            }}
        >
            {children}
        </TrackerContext.Provider>
    )
}