export const DEFAULT_LAOUT_CONFIG = [
    {
        "type": "VIDEO_FEED_COMPONENT",
        "i": "bloomberg",
        "x": 3,
        "y": 8,
        "w": 3,
        "h": 4
    },
    {
        "type": "NEWS_FEED_COMPONENT",
        "i": "piqfeeds",
        "x": 2,
        "y": 0,
        "w": 1,
        "h": 10
    },
    {
        "type": "NEWS_FEED_COMPONENT",
        "i": "forexLive",
        "x": 1,
        "y": 0,
        "w": 1,
        "h": 7
    },
    {
        "type": "CHART_FEED_COMPONENT",
        "i": "investing-economic-calender",
        "x": 6,
        "y": 0,
        "w": 3,
        "h": 4
    },
    {
        "type": "CHART_FEED_COMPONENT",
        "i": "tradingview",
        "x": 6,
        "y": 4,
        "w": 3,
        "h": 4
    },
    {
        "type": "TWITTER_FEED_COMPONENT",
        "i": "squawks-tweets",
        "x": 0,
        "y": 7,
        "w": 2,
        "h": 5
    },
    {
        "type": "NEWS_FEED_COMPONENT",
        "i": "newsquawk-delay",
        "x": 0,
        "y": 0,
        "w": 1,
        "h": 7
    },
    {
        "type": "NEWS_FEED_COMPONENT",
        "i": "mtsinsights",
        "x": 6,
        "y": 8,
        "w": 2,
        "h": 6
    },
    {
        "type": "TUTORIAL_COMPONENT",
        "i": "cmegroup-app",
        "x": 3,
        "y": 0,
        "w": 3,
        "h": 8
    }
];
