import React, { useEffect, useState } from "react";
import { SocialMediaShare } from "../socialMediaShare";
import moment from "moment";
import useFeeds from "src/Hooks/useFeeds";
import { useSelector } from "react-redux";
import { getUrlToShare } from "src/utils/common";
import { useBookmarkContext } from "src/context/BookmarkProvider";
import { escapeRegExp } from "lodash";

function GlobalSearchCard({ data, props, searchedText, setShowContainer }: any) {
  const [img, setImg] = useState("");
  const [title, setTitle] = useState("");
  const [socket, setSocket] = useState("");
  const [twitterAccount, setTwitterAccount] = useState("");
  const { handleShowDetials, handleCopyLink, handleSocialMediaShareData } =
    useFeeds(props);

  const { bookmarkClickHandler } = useBookmarkContext()

  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns;
  });

  const getDate = moment(data?.pubDate).format("MMM Do, HH:mm:ss");

  const handleDetailsPage = () => {
    handleShowDetials(data, data?.sourceName, setShowContainer);
  };

  const highlightedText = data?.title.replace(
    new RegExp(escapeRegExp(searchedText), "gi"),
    `<span class="highlighted_search_text">${searchedText}</span>`
  );

  useEffect(() => {
    const fieldData = Columns.find(
      (item: any) => item.slug === data?.sourceName
    );
    if (fieldData) {
      setTitle(fieldData?.title);
      setImg(fieldData?.icon);
      setTwitterAccount(fieldData?.twitterAccount);
      setSocket(fieldData?.slug);
    }
  }, [data]);

  return (
    <div className="card_container">
      {/* <div className="second_line"></div> */}
      <div className="card_top_container">
        <div className="title_img">
          <img src={img} alt="logo"></img>
        </div>
        <div className="right">
          <div className="left">
            <div className="source_topic">{title}</div>
            <SocialMediaShare
              data={handleSocialMediaShareData(
                data,
                socket as any,
                twitterAccount
              )}
              showShareIcon={true}
              url={"\n\nFull Story → " + getUrlToShare(data, socket as any)}
              onCopyLink={() => handleCopyLink(data, socket as any)}
              bookmarkClickHandler={() => {
                bookmarkClickHandler({
                  ...data,
                  sourceName: socket,
                })
              }}
              article_id={data?._id}
            ></SocialMediaShare>
          </div>
          <div className="date_time">{getDate}</div>
        </div>
      </div>
      <div className="main_title" onClick={() => {
        handleDetailsPage()
      }}>
        <p dangerouslySetInnerHTML={{ __html: highlightedText }} />
      </div>
    </div>
  );
}

export default GlobalSearchCard;
