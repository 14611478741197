import React from "react";
import { message, Radio, Space, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
const CollapsePanelRedioGroup: React.FC<{
  feeds: any[],
  slugList: string[],
  hasNewsQuawkSubscription: boolean,
  socket: string,
  onEditChange: (socket: string, string: any) => any,
  isUserLoggedIn: boolean
}> = ({ feeds, slugList, onEditChange, hasNewsQuawkSubscription, socket, isUserLoggedIn }) => {
  const { hasHammerstoneSubscription }: any = useSelector(
    (state: any) => state.premiumapps
  );
  const { isSignedIn } = useAuthContext()

  return (
    <>
      <Radio.Group
        size="small"
        className="v2-edit-panel-select"
        style={{ height: "max-content", overflow: "hidden" }}
        value={socket}
      >
        <Space direction="vertical">
          {
            feeds?.map((col: any, index: number) => {
              return (
                <Tooltip
                  placement="left"
                  title={
                    slugList?.includes(col?.slug)
                      ? "Feed already in use"
                      : ""
                  }
                  color={"#000"}
                  key={col?._id}
                >
                  <div className={`user-item pointer flex items-center`}>
                    <Radio
                      value={col?.slug}
                      key={col?.slug}
                      style={{ alignItems: "center" }}
                      onChange={() => {
                        if (isSignedIn) {

                          onEditChange(socket, col["slug"])
                        } else {
                          message.warning("To access more X lists please sign up")
                        }



                      }}
                      checked={true}

                      disabled={slugList?.includes(col?.slug) ||
                        (!hasNewsQuawkSubscription && col?.slug === "newsquawk") ||
                        (col?.slug === "newsquawk-delay" && !isUserLoggedIn) ||
                        (col?.slug === "newsquawk" && !isUserLoggedIn) ||
                        (!hasHammerstoneSubscription && col?.slug === "hammerstone") ||
                        (col?.slug === "hammerstone" && !isUserLoggedIn)
                      }
                    >
                      {col?.icon && <img
                        src={col?.icon}
                        alt=""
                        style={{
                          height: "10px",
                          width: "10px",
                          marginRight: "4px",
                        }}
                      />}
                      <span
                        className={" p-y-10 edit-layout-label-seetings"}
                        style={{
                          fontWeight: "400",
                          lineHeight: "10px",
                          color: slugList?.includes(col?.slug)
                            ? "grey"
                            : "inherit",
                        }}
                      >
                        {col.title}
                      </span>
                    </Radio>
                  </div>
                </Tooltip>
              );
            })}
        </Space>
      </Radio.Group>
    </>
  );
};

export default CollapsePanelRedioGroup;