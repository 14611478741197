import { Modal, message } from "antd";

class ModalMessage {
  successMessage = (success_msg: any) => {
    Modal.success({
      content: success_msg,
    });
  };

  warningMessage = (warning_msg: any) => {
    Modal.warning({
      content: warning_msg,
    });
  };

  errorMessage = (error_msg: any) => {
    Modal.error({
      content: error_msg,
    });
  };
}

export const modalMessage = new ModalMessage();

class PopMessage {
  successMessage = (success_msg: any, duration?: number) => {
    message.success({
      content: success_msg,
      duration: duration
    });
  };

  warningMessage = (warning_msg: any) => {
    message.warning({
      content: warning_msg,
    });
  };

  errorMessage = (error_msg: any) => {
    message.error({
      content: error_msg,
    });
  };
  loadingMessage = (loding_message: string, duration: number) => {
    message.loading({
      content: loding_message,
      duration: duration,
    });
    // Dismiss manually and asynchronously
  };
}

export const popMessage = new PopMessage();
