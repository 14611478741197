import { Amplify } from "aws-amplify";
import awsmobile from "./amplify-config-json";

// Amplify.configure({
//   Auth: {
//     // region: "us-east-2",
//     // userPoolId: "us-east-2_BTiSYJWYD",
//     // userPoolWebClientId: "283f36r86co4d6ie755o5motdp",
//     region: process.env.REACT_APP_AWS_REGION,
//     userPoolId: process.env.REACT_APP_AWS_POOL_ID,
//     userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
//     // region: process.env.REACT_APP_COG_R,
//     // userPoolId: process.env.REACT_APP_COG_UPID,
//     // userPoolWebClientId: process.env.REACT_APP_COG_UPWCID,
//   },
// });


Amplify.configure(awsmobile)