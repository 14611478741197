import { SocketNames } from "src/interface/PiqState";

export const getIframeUrl = (socket: SocketNames, darkMode: string) => {
  const theme = darkMode ? "dark" : "light";

  switch (socket) {
    case "electionwinnertrump":
      return `https://embed.polymarket.com/market.html?market=will-donald-trump-win-the-2024-us-presidential-election&features=volume&theme=${theme}`;
    case "electionwinnerkamala":
      return `https://embed.polymarket.com/market.html?market=will-kamala-harris-win-the-2024-us-presidential-election&features=volume&theme=${theme}`;
    case "popularvotewinnertrump":
      return `https://embed.polymarket.com/market.html?market=will-donald-trump-win-the-popular-vote-in-the-2024-presidential-election&features=volume&theme=${theme}`;
    case "popularvotewinnerkamala":
      return `https://embed.polymarket.com/market.html?market=will-kamala-harris-win-the-popular-vote-in-the-2024-presidential-election&features=volume&theme=${theme}`;
    case "willbidenfinishhisterm":
      return `https://embed.polymarket.com/market.html?market=will-biden-complete-his-term-as-president&features=volume&theme=${theme}`;
    default:
      return "";
  }
};

export const polymarketWidgetSockets = [
  "electionwinnertrump",
  "electionwinnerkamala",
  "popularvotewinnertrump",
  "popularvotewinnerkamala",
  "willbidenfinishhisterm",
];
