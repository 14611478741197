import ReactGA from "react-ga4";
import Plausible from "../../../plausible";
export const initGA = () => {
  ReactGA.initialize("G-79F0LHK676");
};

export const trackingPageGA = (page: string) => {
  ReactGA.send({
    hitType: "pageview",
    page: page,
  });
  Plausible.trackPage(page);
};

export const useAnalyticsEventTracker = (category = "category") => {
  const eventTracker = (action = "action", label = "label") => {
    ReactGA.event({ category, action, label });
    Plausible.trackEvent(`${action}_${label}`);
  };
  return eventTracker;
};

export const setUserDataGA = (data: any) => {
  ReactGA.set({
    data,
  });
};
