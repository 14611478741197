export const limitExceedTracker = () => {
  const alreadyCounted: null | string = localStorage.getItem("q-limit-z");
  if (alreadyCounted) {
    const alreadyCountedInObject = JSON.parse(alreadyCounted);
    const alreadyCountedDate = new Date(alreadyCountedInObject.date);
    const alreadyCountedMonth = alreadyCountedDate.getUTCMonth();
    const alreadyCountedYear = alreadyCountedDate.getUTCFullYear();

    const currentDate = new Date();
    const currentMonth = currentDate.getUTCMonth();
    const currentYear = currentDate.getUTCFullYear();

    // Check if the stored date is within the same month and year

    if (
      alreadyCountedMonth !== currentMonth ||
      alreadyCountedYear !== currentYear
    ) {
      const dateAndCount = {
        date: new Date(),
        count: 0,
      };
      localStorage.setItem("q-limit-z", JSON.stringify(dateAndCount));
    }
  } else {
    const dateAndCount = {
      date: new Date(),
      count: 0,
    };
    localStorage.setItem("q-limit-z", JSON.stringify(dateAndCount));
  }
};

export const limitIncreaser = (user: any) => {
  if (user) {
    return false;
  }
  const alreadyCounted: null | string = localStorage.getItem("q-limit-z");
  if (alreadyCounted) {
    const alreadyCountedInObject = JSON.parse(alreadyCounted);
    const count = alreadyCountedInObject.count;
    if (count < 5) {
      localStorage.setItem(
        "q-limit-z",
        JSON.stringify({
          ...alreadyCountedInObject,
          count: count + 1,
        })
      );
      return false;
    } else {
      return true;
    }
  } else {
    const dateAndCount = {
      date: new Date(),
      count: 0,
    };
    localStorage.setItem("q-limit-z", JSON.stringify(dateAndCount));
  }
};
