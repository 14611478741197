import socketIOClient from "socket.io-client";
import config from "../config";

const createSocket = (url: string) => socketIOClient(url, {
  auth: { token: "" },
  reconnection: true,
  autoConnect: false,
  reconnectionAttempts: 1000,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
});

export const socket: any = createSocket(config.FEED_URL ?? "");
export const clientSocket: any = createSocket(config.MAIN_URL ?? "");

export const runSocketConnect = async (token: string) => {
  if (socket && clientSocket) {
    [socket, clientSocket].forEach(s => {
      s.disconnect();
      s.auth.token = token;
      s.connect();
    });
  }
};

const emitEvent = (eventName: string, token: string) => {
  if (socket && clientSocket) {
    [socket, clientSocket].forEach(s => {
      s.emit(eventName, { token });
    });
  }
};

export const logoutSocketEmit = (token: string) => emitEvent("logout", token);
export const loginSocketEmit = (token: string) => emitEvent("login", token);
