import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  axiosInstance,
  remove_auth_headers,
  updateAxiosInstance,
} from "src/store/utility";
import jwt from "jsonwebtoken";
import { useDispatch } from "react-redux";
import { getSuiteConfig, logoutReset, setUserDetails } from "src/store/User/action";
import { updateLoading } from "src/store/Loading";
import { loginSocketEmit, runSocketConnect } from "src/Socket";
import plausible from "src/plausible";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { popMessage } from "src/development/Component/message/message";
import { limitExceedTracker } from "src/utils/limitExceed";
import { check_if_token_is_expired, update_user_details } from "src/store/userStore";
import { useLayoutContext } from "src/context/LayoutProvider";
import useDashboard from "./useDashboard";
import { useTrackerContext } from "src/context/TrackerProvider";
import useLogout from "./useLogout";
import useAuthForm from "./useLoginForm";
import { defaultColumnCoordinates } from "src/SuiteConfig/columnConfiguration";
import { fetchCustomFeedUserData } from "src/store/PiQColumns";
import { trackEvents } from "src/constants/tracker";

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { sendEvent, trackLoginSuccess } = useTrackerContext();
  const { setPiqSuiteLayout } = useLayoutContext()
  useEffect(() => {
    checkUser();
    const interval = setInterval(() => {
      checkUser();
    }, 36e5);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetch_user_suite = async () => {
    await getSuiteConfig()
      .then((res: any) => {
        setPiqSuiteLayout(res?.data?.layout);
        dispatch({
          type: "USER_TOGGLE_DARK_MODE",
          mode: res?.data?.darkMode,
        });
      })
      .catch((err) => {
        dispatch(check_if_token_is_expired(err.response))
        setPiqSuiteLayout(defaultColumnCoordinates);
      })
      .finally(() => {
        dispatch(
          updateLoading({ key: "dashboardloading", value: false }) as any
        );
      });
  }



  useEffect(() => {
    Hub.listen("auth", async (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {

        sign_in_user()
        checkUser();
      }
      if (payload.event === "cognitoHostedUI") {
        checkGmailSignIn();
        checkUser();
      }
      if (payload.event === "signOut") {
        setUser(null);
        setIsSignedIn(false);
      }

      if (payload.event === "configured") {
        checkUser();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sign_in_user = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);

      const authToken = user.signInUserSession.accessToken.jwtToken;
      updateAxiosInstance(authToken);

      const countryCode = localStorage.getItem("countryCode");
      const response = await axiosInstance.post(`/auth/saveUser`, { countryCode });

      const { email, _id, given_name, family_name } = response.data.data;

      trackLoginSuccess({
        email,
        user_id: _id,
        first_name: given_name,
        last_name: family_name
      });

      dispatch(fetchCustomFeedUserData());
      await fetch_user_suite();
      dispatch(setUserDetails(response.data.data));
      dispatch(update_user_details(response.data.data));
      dispatch(fetchSubscriptionData());
      setIsSignedIn(true);
      loginSocketEmit(authToken);
      // trackLoginSuccess({
      //   email: response?.data?.data?.email,
      //   user_id: response?.data?.data?._id,
      //   first_name: response?.data?.data?.given_name,
      //   last_name: response?.data?.data?.family_name,
      // });
    } catch (error: any) {
      const errorResponse = error.response;
      if (errorResponse?.data?.msg) {
        popMessage.errorMessage(errorResponse.data.msg);
      }
      remove_auth_headers();
      setIsSignedIn(false);
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }));
    }
  };

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await getToken();
      setUser(user);
      setIsSignedIn(true);
    } catch (err) {
      limitExceedTracker();
      setIsSignedIn(false);
      updateAxiosInstance("");
    }
  };

  const checkGmailSignIn = async () => {
    try {
      dispatch(updateLoading({ key: "dashboardloading", value: true }));
      if (!Auth || typeof Auth.signIn !== "function") {
        throw new Error("No Auth module found, please ensure @aws-amplify/auth is imported");
      }

      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken);


      await fetch_user_suite();
      plausible.trackEvent("Login");

      const queryParamValue = localStorage.getItem("utm_source");
      if (queryParamValue) {
        plausible.trackEvent(`${queryParamValue}Signin`, { utm_source: queryParamValue });
        localStorage.removeItem("utm_source");
      }

      const countryCode = localStorage.getItem("countryCode");
      const response = await axiosInstance.post(`/auth/saveUser`, { countryCode });

      dispatch(setUserDetails(response.data.data));
      dispatch(update_user_details(response.data.data));
      dispatch(fetchSubscriptionData());
      setIsSignedIn(true);
      loginSocketEmit(authToken);
      sendEvent({
        eventName: trackEvents.SIGNUP_SUCCESS
      })
      // trackLoginSuccess({
      //   email: response?.data?.data?.email,
      //   user_id: response?.data?.data?._id,
      //   first_name: response?.data?.data?.given_name,
      //   last_name: response?.data?.data?.family_name,
      // });
    } catch (err: any) {
      console.error("Gmail sign-in error:", err);

      if (err.response?.data?.msg) {
        popMessage.errorMessage(err.response.data.msg);
      } else {
        popMessage.errorMessage("An error occurred during sign-in. Please try again.");
      }

      // Uncomment and adjust these lines if needed for specific error handling
      // if (err.code === "UserNotConfirmedException") {
      //   setEmailAddress(username);
      //   await Auth.resendSignUp(username);
      //   localStorage.setItem("piq-user-emailAddress", username);
      //   setActiveDrawer("confirm-signup-drawer");
      // } else if (err.code === "PasswordResetRequiredException") {
      //   await Auth.resendSignUp(username);
      //   setActiveDrawer("forgot-password-drawer");
      // }
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }));
    }
  };

  const getToken = async () => {
    try {
      const currentSession: any = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();
      setToken(accessToken);

      const decodedPayload: any = jwt.decode(accessToken);
      const expirationTime = decodedPayload?.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime <= expirationTime) {
      } else {
        const time = setTimeout(async () => {
          dispatch(logoutReset() as any);
        }, 5000);
        return () => clearTimeout(time);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return { user, token, isSignedIn, checkGmailSignIn };
};
