export const newsquawkDummyData = [
  {
    _id: {
      $oid: "66c44f1c44c256fc4852f0d3",
    },
    slug: "cityfibre-and-sky-sign-partnership-to-bring-sky-full-fibre-broadband-to-cityfibre-network-says-work-is-underway-to-ensure-sky-s-full-fibre-broadbrand-will-be-available-to-people-on-the-cityfibre-network-in-2025-20-08-2024",
    analysis_updated_at: {
      $date: "2024-08-20T08:06:35.000Z",
    },
    canonicalUrl: "",
    content: "",
    fullContent:
      "\n\n Analysis:\n<ul><li><strong>BT (BT/ LN) </strong>shares have been trading lower today by some 4.5% following a report in The Telegraph suggesting that Sky is set to sign a broadband deal with CityFibre, which is one of the Co.'s main rivals.</li></ul>",
    is_highlighted: false,
    is_important: false,
    lastUpdated: {
      $date: "2024-08-20T08:06:37.000Z",
    },
    originTitle: "Newswires",
    pubDate: {
      $date: "2024-08-20T08:04:00.000Z",
    },
    reaction_updated_at: null,
    section: "FTSE 100",
    title:
      "CityFibre and Sky sign partnership to bring Sky full fibre broadband to CityFibre network; says work is underway to ensure Sky's full fibre broadbrand will be available to people on the CityFibre network in 2025",
  },
  {
    _id: {
      $oid: "66c5b05044c256fc488c3f05",
    },
    slug: "mt-gox-transferred-usd-75mln-in-bitcoin-to-bitstamp-according-to-arkham-cited-by-block-pro-21-08-2024",
    analysis_updated_at: null,
    canonicalUrl: "",
    content: "",
    fullContent: "",
    is_highlighted: false,
    is_important: false,
    lastUpdated: {
      $date: "2024-08-21T09:12:06.000Z",
    },
    originTitle: "Newswires",
    pubDate: {
      $date: "2024-08-21T09:11:00.000Z",
    },
    reaction_updated_at: null,
    section: "Crypto",
    title:
      "Mt.Gox transferred USD 75mln in Bitcoin to BitStamp, according to Arkham cited by Block Pro",
  },
  {
    _id: {
      $oid: "66ea52d444c256fc48e53756",
    },
    slug: "uk-government-is-to-reduce-spending-on-non-essential-consultancy-according-to-ft-18-09-2024",
    analysis_updated_at: null,
    canonicalUrl: "",
    content: "",
    fullContent: "",
    is_highlighted: false,
    is_important: false,
    lastUpdated: {
      $date: "2024-09-18T04:07:13.000Z",
    },
    originTitle: "FT",
    pubDate: {
      $date: "2024-09-18T04:06:00.000Z",
    },
    reaction_updated_at: null,
    section: "Economic Commentary",
    title:
      "UK government is to reduce spending on non-essential consultancy, according to FT",
  },
  {
    _id: {
      $oid: "6716ec31fac381d040097df0",
    },
    slug: "asia-pac-stocks-are-subdued-following-the-lacklustre-lead-from-wall-st-where-the-major-indices-finished-mostly-lower-amid-a-higher-yield-environment-although-tech-outperformed-amid-gains-in-the-mega-cap-tech-names-22-10-2024",
    analysis_updated_at: null,
    canonicalUrl: "",
    content: "",
    fullContent: "",
    is_highlighted: true,
    is_important: false,
    lastUpdated: {
      $date: "2024-10-22T00:05:06.000Z",
    },
    originTitle: "Newsquawk",
    pubDate: {
      $date: "2024-10-22T00:01:00.000Z",
    },
    reaction_updated_at: null,
    section: "Market Analysis",
    title:
      "Asia-Pac stocks are subdued following the lacklustre lead from Wall St where the major indices finished mostly lower amid a higher yield environment although tech outperformed amid gains in the mega-cap tech names",
  },
];

export const hammerstoneDummyData = [
  {
    _id: {
      $oid: "66edab3d44c256fc486d69eb",
    },
    slug: "jnj-darzalex-daratumumab-based-quadruplet-regimen-receives-positive-chmp-opinion-for-transplant-eligible-patients-with-newly-diagnosed-multiple-myeloma",
    author: "HsMkt16",
    canonicalUrl: "",
    createdAt: {
      $date: "2024-09-20T17:05:01.031Z",
    },
    fullContent:
      "<b>$JNJ</b> darzalex® (daratumumab)-based quadruplet regimen receives positive chmp opinion for transplant-eligible patients with newly diagnosed multiple myeloma",
    msgId: 648884,
    pubDate: {
      $date: "2024-09-20T17:00:31.000Z",
    },
    stocks: ["JNJ"],
    title:
      "$JNJ darzalex® (daratumumab)-based quadruplet regimen receives positive chmp opinion for transplant-eligible patients with newly diagnosed multiple myeloma",
    updatedAt: {
      $date: "2024-09-23T10:25:01.279Z",
    },
  },
  {
    _id: {
      $oid: "66edc50444c256fc4871410e",
    },
    slug: "gme-still-going-new-highs-11",
    author: "HsMkt4",
    canonicalUrl: "",
    createdAt: {
      $date: "2024-09-20T18:55:00.887Z",
    },
    fullContent: "$GME still going, new highs +11%",
    msgId: 648982,
    pubDate: {
      $date: "2024-09-20T18:52:45.000Z",
    },
    stocks: ["GME"],
    title: "$GME still going, new highs +11%",
    updatedAt: {
      $date: "2024-09-23T10:55:01.263Z",
    },
  },
  {
    _id: {
      $oid: "66edc63044c256fc48716d0c",
    },
    slug: "iot-highs-35",
    author: "HsMkt4",
    canonicalUrl: "",
    createdAt: {
      $date: "2024-09-20T19:00:00.965Z",
    },
    fullContent: "$IOT highs +3.5%",
    msgId: 648988,
    pubDate: {
      $date: "2024-09-20T18:59:05.000Z",
    },
    stocks: ["IOT"],
    title: "$IOT highs +3.5%",
    updatedAt: {
      $date: "2024-09-23T10:55:01.263Z",
    },
  },
  {
    _id: {
      $oid: "66edc63044c256fc48716d0d",
    },
    slug: "feds-harker-forecasts-risks-to-inflation-and-employment-as-balanced",
    author: "HsMkt4",
    canonicalUrl: "",
    createdAt: {
      $date: "2024-09-20T19:00:00.965Z",
    },
    fullContent:
      "Fed's Harker forecasts risks to inflation and employment as balanced",
    msgId: 648990,
    pubDate: {
      $date: "2024-09-20T18:59:16.000Z",
    },
    stocks: [],
    title:
      "Fed's Harker forecasts risks to inflation and employment as balanced",
    updatedAt: {
      $date: "2024-09-23T10:55:01.263Z",
    },
  },
];
