import React from 'react'

export default function BreakingTag() {
    return (
        <>
            <span className='breaking-tag'>
                BREAKING
            </span>
        </>
    )
}
