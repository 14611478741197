import { UpOutlined } from "@ant-design/icons";
import React from "react";



const ScrollToTop: React.FC<{
    displayScrollTop: boolean;
    executeScrollToTop: () => void;
}> = ({ displayScrollTop, executeScrollToTop }) => {
    return (
        <div
            className="back-to-top-fab"
            onClick={() => executeScrollToTop()}
            style={{
                opacity: displayScrollTop ? 1 : 0,
            }}
        >
            <UpOutlined />
            <div className="material-icons"></div>
        </div>
    )
}

export default ScrollToTop;