import { Tooltip } from "antd";
import React from "react";
import important_tag from "src/style/images/Important-tag.svg";
import news_tag from "src/style/images/bank-news-icon.svg";
import other_data_tag from "src/style/images/other-data-icon.svg";
import economic_tag from "src/style/images/economic-commentary.svg";
import { useSelector } from "react-redux";
import { checkIfDivHasHighlight } from "src/utils/utilFunction";

export default function StockTag({ item, colConfig }: any) {
    const {
        darkMode,
    } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));
    return (
        <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {item?.stocks?.map((stock: any, i: number) => {
                    return (

                        <>
                            <div
                                className="section-imp-tag"
                                style={{ display: "flex", alignItems: "baseline" }}
                                key={i}
                            >
                                <span
                                    className="section-tag stock-tag-name"
                                    style={{
                                        background: darkMode
                                            ? checkIfDivHasHighlight(item?.title, colConfig)
                                                ? "rgb(56, 66, 81)"  // when both darkMode and highlight are true
                                                : "#515A67"          // when only darkMode is true
                                            : "rgba(52, 52, 89, 0.20)", // when darkMode is false,
                                        color: darkMode ? "#FFF" : "#454E5C"
                                    }}
                                >
                                    ${stock}
                                </span>
                            </div>
                        </>)
                })}
            </div>
        </>
    );
}
