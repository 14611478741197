import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import config from "src/config";
import { updateDataOnVersion } from "src/store/User/action";

export const PiqVersionContext = createContext<{
    version: string | null;
    updateVersion: (version?: string) => void;
}>({
    version: null,
    updateVersion: () => { }
})

export const usePiqVersionContext = () => React.useContext(PiqVersionContext);

export const PiqVersionProvider: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {

    const [version, setVersion] = React.useState<string | null>(null);

    const dispatch = useDispatch();

    React.useEffect(() => {

        updateVersion();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateVersion = (version?: string) => {
        const current = localStorage.getItem('PIQ_VERSION');
        const ver = version || config.PIQ_VERSION.toString();
        if (current && current === ver) {
            setVersion(current);
        } else {
            window.localStorage.removeItem("piq-suite-config")
            dispatch(updateDataOnVersion());
            setVersion(ver);
            localStorage.setItem('PIQ_VERSION', ver);
        }

    }


    return (
        <PiqVersionContext.Provider value={{
            version,
            updateVersion
        }}>
            {children}
        </PiqVersionContext.Provider>
    )
}