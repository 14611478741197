import { Loading3QuartersOutlined } from '@ant-design/icons';
import React, { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getIframeUrl } from 'src/constants/polymarketWidget';
import ColumnHeader from 'src/development/Component/ColumnHeader/Column-Header';
import ColumnHeaderOption from 'src/development/Component/ColumnHeader/Column-Header-Option';
import useFeeds from 'src/Hooks/useFeeds';
import { PolymarketWidgetProps } from 'src/interface/PolymarketWidget';

const PolymarketWidget: React.FC<PolymarketWidgetProps> = ({ socket }) => {
    const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const { getColumnDetail } = useFeeds();
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    const column = getColumnDetail(socket);
    const iframeUrl = useMemo(() => getIframeUrl(socket, darkMode), [socket, darkMode]);

    const refresh_function = () => {
        setIsFetching(true);
        if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src;
        }
    };

    return (
        <div className={`view-chart-div polymarket-container ${darkMode ? 'dark' : 'light'}`}>
            <ColumnHeader
                title={column?.title || ""}
                socket={socket}
                isFetching={isFetching}
                fetchFunction={refresh_function}
                handleEditPanel={() => setShowEditPanel(true)}
            />
            {showEditPanel && socket ? (
                <ColumnHeaderOption
                    setShowEditPanel={setShowEditPanel}
                    socket={socket}
                />
            ) : (
                <div className="iframe-container">
                    {
                        isFetching && <div className="loading">
                            <Loading3QuartersOutlined spin />
                        </div>
                    }
                    <iframe
                        ref={iframeRef}
                        src={iframeUrl}
                        allow="clipboard-write"
                        onLoad={() => setIsFetching(false)}
                    ></iframe>
                </div>
            )
            }
        </div >
    );
};

export default PolymarketWidget;
