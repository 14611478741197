import React from "react";

const OriginTitleComponent: React.FC<{ item: any; socket: any }> = ({
  item,
  socket,
}) => {
  return (
    <>
      {item?.originTitle && (
        <div className="v2-suite-column-card-title" style={{ marginBottom: 4 }}>
          {socket === "newsquawk" || socket === "newsquawk-delay"
            ? `Source: ${item?.originTitle} `
            : item?.originTitle}
        </div>
      )}
    </>
  );
};

export default OriginTitleComponent;
