import { Tooltip } from "antd";
import React from "react";
import important_tag from "src/style/images/Important-tag.svg";
import news_tag from "src/style/images/bank-news-icon.svg";
import other_data_tag from "src/style/images/other-data-icon.svg";
import economic_tag from "src/style/images/economic-commentary.svg";

export default function SectionTag({ item }: any) {
  type SectionColorKeys =
    | "Other Central Banks"
    | "European Equities"
    | "Other Data";

  const sectionColor: any = {
    "Other Central Banks": "#4F48E2",
    "European Equities": "#0052B4",
    "Other Data": "#F27200",
    // "other": "#1D1D4B", // Uncomment and add if necessary
  };

  return (
    <div
      className="section-imp-tag"
      style={{ display: "flex", alignItems: "baseline" }}
    >
      <span
        className="section-tag"
        style={{
          background: item?.section
            ? sectionColor[item?.section] || "#1D1D4B"
            : "#F27200",
        }}
      >
        {item?.section}
      </span>

      {item?.is_important && (
        <Tooltip placement="top" title={"Important"} color={"#000"}>
          <div
            //   className={`user-item pointer items-center1`}
            style={{ cursor: "pointer" }}
          >
            <img
              src={important_tag}
              className={`sidebar-article-icon`}
              alt="imp"
            />
          </div>
        </Tooltip>
      )}

      {item?.section === "Other Central Banks" && (
        <Tooltip placement="top" title={"Other Central Banks"} color={"#000"}>
          <div
            //   className={`user-item pointer items-center1`}
            style={{ marginLeft: "4px", cursor: "pointer" }}
          >
            <img src={news_tag} className={`sidebar-article-icon`} alt="imp" />
          </div>
        </Tooltip>
      )}
      {item?.section === "Other Data" && (
        <Tooltip placement="top" title={"Other Data"} color={"#000"}>
          <div
            //   className={`user-item pointer items-center1`}
            style={{ marginLeft: "4px", cursor: "pointer" }}
          >
            <img
              src={other_data_tag}
              className={`sidebar-article-icon`}
              alt="imp"
            />
          </div>
        </Tooltip>
      )}
      {item?.section === "Economic Commentary" && (
        <Tooltip placement="top" title={"Economic Commentary"} color={"#000"}>
          <div
            //   className={`user-item pointer items-center1`}
            style={{ marginLeft: "4px", cursor: "pointer" }}
          >
            <img
              src={economic_tag}
              className={`sidebar-article-icon`}
              alt="imp"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
