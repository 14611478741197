import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: false,
  feedDetailClicked: false,
};

export const limitExceedSlice = createSlice({
  name: "limitexceeed",
  initialState,
  reducers: {
    falseLimitExceed: (state: any) => {
      state.limit = false;
    },
    trueLimitExceed: (state: any) => {
      state.limit = true;
    },
    changeFeedDetailClicked: (state: any, action: any) => {
      state.feedDetailClicked = action.payload;
    },
  },
});

export const { falseLimitExceed, trueLimitExceed, changeFeedDetailClicked } =
  limitExceedSlice.actions;
export default limitExceedSlice.reducer;
