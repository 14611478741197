import Plausible from "plausible-tracker";
import { Auth } from "aws-amplify";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Initialize an agent at application startup.

const TRACK_LOCALHOST = true;
// console.log(window.location.host, "location");
const { trackPageview, trackEvent } = Plausible({
  domain: "app.piqsuite.com",
  // domain: isLocalhost
  //   ? "piqsuite.com"
  //   : window?.location?.host || "piqsuite.com",
  apiHost: "https://plausible.corp.black",
  trackLocalhost: TRACK_LOCALHOST,
  hashMode: false,
});

const PrivatePlausibleService = class PrivatePlausibleService {
  static visitorId = "";

  constructor() {
    FingerprintJS.load()
      .then((fp) => {
        fp.get().then((ndp) => {
          PrivatePlausibleService.visitorId = ndp.visitorId;
        });
      })
      .catch((err) => {
        console.warn("Failed to generate fingerprint");
        console.warn(err);
      });
  }

  async getUser() {
    const userObject = {
      fp: PrivatePlausibleService.visitorId,
      user: undefined,
    };
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      // @ts-ignore
      userObject.user = {
        id: authUser.getUserId(),
      };
    } catch (err) {
      // console.log(err);
    }
    return userObject;
  }

  async trackPage(link: string) {
    trackPageview({
      trackLocalhost: TRACK_LOCALHOST,
      url: link,
    });
  }

  async trackEvent(name: string, props: Object = {}) {
    let updateProps = props;
    updateProps = Object.assign({}, this.getUser(), updateProps);
    trackEvent(name, updateProps);
  }
};

class PlausibleService {
  static instance = new PrivatePlausibleService();

  constructor() {
    throw new Error("Use Singleton.getInstance()");
  }

  static getInstance() {
    if (!PlausibleService.instance) {
      PlausibleService.instance = new PrivatePlausibleService();
    }
    return PlausibleService.instance;
  }
}

export default PlausibleService.getInstance();