import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  message,
  Select,
  Skeleton,
  Switch,
} from "antd";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { filterOptions, firstFilterOptions } from "src/constants/filter";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
import useFilter from "src/Hooks/useFilter";


const FilterPanel: React.FC<{
  socket: string;
}> = ({ socket }) => {
  const [showInputCount, setShowInputCount] = useState<Number | null>(null)

  const darkMode = useSelector((state: any) => state?.user?.darkMode);


  const {
    colFilterConfig: data,
    sendFilterConfig,
    loading,
    setColFilterConfig,
  } = useContext(IndividualFeedContext);
  const filters = useMemo(() => data?.filters, [data]);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    addFilterHandler,
    changeHandler,
    removeFilterHandler,
    clearFilterHandler,
    applyFilterHandler
  } = useFilter({
    filters,
    sendFilterConfig,
    data,
    messageApi,
    socket
  })

  useEffect(() => {
    if (filters?.length === 0) {
      setColFilterConfig((prev) => ({ ...prev, is_active: false }))
    }

  }, [filters])



  return (
    <>
      <div
        className={`filter-panel-container ${darkMode ? "filter-panel-dark" : ""}`}
        style={{
          display: "grid",
          gap: "4px",
        }}
        hidden={loading}
      >
        <span
          style={{
            opacity: ".7",
            marginBottom: "3px"
          }}
        >
          Filter new posts coming into this feed
        </span>
        {contextHolder}
        {filters?.map((item, i) => (
          <div
            key={i}
            style={{
              width: "100%",
              display: "flex",
              gap: "2px",
              alignItems: "center"
            }}
          >
            <Select
              onChange={(val) => {
                changeHandler("boolean", i, val);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              size="small"
              style={{
                fontSize: "10px",
              }}
              defaultValue={i === 0 ? firstFilterOptions[0]?.value : filterOptions[0]?.value}
              value={item?.boolean}
              options={i === 0 ? firstFilterOptions : filterOptions}
            />
            <Input
              placeholder="Type word or phrase"
              size="small"
              showCount={showInputCount === i}
              onFocus={(e) => {
                setShowInputCount(i)
              }}
              allowClear
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              style={{
                fontSize: "10px",
                height: "100%",
              }}
              onBlur={(e) => {
                setShowInputCount(null)
                changeHandler("keyword", i, e.target.value.trim());
              }}
              maxLength={25}
              onChange={(e) => {
                setColFilterConfig((prev) => ({
                  ...prev,
                  filters: prev?.filters?.map((it, idx) =>
                    idx === i
                      ? {
                        ...it,
                        keyword: e.target.value,
                      }
                      : it
                  ),
                }));
              }}
              value={item?.keyword}
            />
            <div className="crossBtn" onClick={() => removeFilterHandler(i)}>
              {<CloseOutlined />}
            </div>
          </div>
        ))}
        <div
        >

          <Button size="small" style={{
            fontSize: "9px",
            padding: "8px"
          }} className="add-btn btn" onClick={addFilterHandler}>
            <PlusCircleOutlined /> Add Condition
          </Button>
        </div>
        <div className="switch-container">
          {
            filters?.length > 0 &&
            <span className="clear-filter" onClick={clearFilterHandler}>
              Clear Filter
            </span>
          }
          <div className="switch" >
            <Switch
              onChange={applyFilterHandler}
              size="small"
              checked={data?.is_active}
              disabled={filters?.length === 0}
            />
            <span>Apply Filter</span>
          </div>
        </div>

      </div>
      {loading && <Skeleton active loading={loading} />}

    </>
  );
};

export default FilterPanel;
