import * as actionTypes from "./actionType";
import { updateObject } from "../utility";

export const initialState = {
  error: null,
  loading: null,
  piqads: [],
  piqadsDetail: null,
};

const piqadsGetStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const piqadsGetSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    piqads: action.piqads,
  });
};
const piqadsGetFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const piqadsDetailStart = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const piqadsDetailSuccess = (state: any, action: any) => {
  return updateObject(state, {
    error: null,
    loading: false,
    piqadsDetail: action.piqadsDetail,
  });
};
const piqadsDetailFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.PIQADS_GET_START:
      return piqadsGetStart(state, action);
    case actionTypes.PIQADS_GET_SUCCESS:
      return piqadsGetSuccess(state, action);
    case actionTypes.PIQADS_GET_FAIL:
      return piqadsGetFail(state, action);

    case actionTypes.PIQADS_DETAIL_START:
      return piqadsDetailStart(state, action);
    case actionTypes.PIQADS_DETAIL_SUCCESS:
      return piqadsDetailSuccess(state, action);
    case actionTypes.PIQADS_DETAIL_FAIL:
      return piqadsDetailFail(state, action);

    default:
      return state;
  }
};
export default reducer;
