import React from "react";
import { useSelector } from "react-redux";

import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import useColumnHeader from "../../../Hooks/useColumnHeader";
import { useAuthContext } from "src/context/AuthProvider";
import CollapsePanelRedioGroup from "../CollapsePanelRedioGroup";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";
import { useIndividualFeedContext } from "src/context/IndividualFeedProvider";
import { getColumnLayoutConfiguration } from "src/utils/utilFunction";
import { sortByTitle } from "src/utils/common";
import { useLayoutContext } from "src/context/LayoutProvider";

const { Panel } = Collapse;

const EditPanel: React.FC<{
  socket: string;
}> = ({ socket }) => {
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const { partnerfeeds, piqnewsfeeds, premiumfeeds, twitterfeeds } = useSelector((state: any) => state.piqcolumns)

  const { sendFeedSesionDuration } = useIndividualFeedContext();

  const { sendEvent } = useTrackerContext();
  const { piqSuiteLayout } = useLayoutContext()
  const onEditChange = (oldCol: string, newCol: string) => {
    setShowSaveDialog(true);
    sendFeedSesionDuration(socket);
    const columnConfiguration = getColumnLayoutConfiguration({
      piqSuiteLayout,
      socket
    })
    sendEvent({
      eventName: trackEvents.FEED_CHANGE,
      payload: {
        feed_from: oldCol,
        feed_to: newCol,
        x: columnConfiguration?.x,
        y: columnConfiguration?.y,
        h: columnConfiguration?.h,
        w: columnConfiguration?.w
      }
    })
    handleUpdateSaveColumn({ oldCol, newCol });
  };



  const {
    handleUpdateSaveColumn,
    alreadySelectedDialog,
    setShowSaveDialog,
    setAlreadySelectedDialog,
  } = useColumnHeader();

  const socketNames = piqSuiteLayout?.map((item: any) => item.i);


  const { subscribedData, hasNewsQuawkSubscription, hasHammerstoneSubscription }: any = useSelector(
    (state: any) => state.premiumapps
  );

  const { isSignedIn: isUserLoggedIn } = useAuthContext();

  const customeFeeds = useSelector((state: any) => {
    return state?.piqcolumns?.customFeeds
  })
  return (
    <div
      className="v2-edit-panel"
      style={{ background: darkMode ? "#384251" : "#ffffff" }}
    >
      <Collapse
        accordion
        className="v2-edit-panel-collapse"
        bordered={false}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header="Partner Feeds"
          key="1"
          style={{ border: "none", margin: "5px 0" }}
        >
          <CollapsePanelRedioGroup
            feeds={sortByTitle(partnerfeeds)}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>

        <Panel
          header="PiQ X Lists"
          key="3"
          style={{ border: "none", marginBottom: "5px" }}
        >
          <CollapsePanelRedioGroup
            feeds={sortByTitle(twitterfeeds)}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>

        <Panel
          header="PiQ News Feeds"
          key="4"
          style={{ border: "none", marginBottom: "5px" }}
        >

          <CollapsePanelRedioGroup
            feeds={sortByTitle(piqnewsfeeds)}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>
        <Panel
          header="Premium Feeds"
          key="5"
          style={{ border: "none", marginBottom: "5px" }}
        >

          <CollapsePanelRedioGroup
            feeds={sortByTitle(premiumfeeds)}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>
        <Panel
          header="Custom Feeds"
          key="6"
          style={{ border: "none", marginBottom: "5px" }}
        >

          <CollapsePanelRedioGroup
            feeds={sortByTitle(customeFeeds)}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>
      </Collapse>

      <div
        hidden={!alreadySelectedDialog}
        className="edit-save-dialog"
        style={{ background: darkMode ? "black" : "#ffffff" }}
      >
        <div className="red-text">
          You already have this feed selected. Please choose another.
        </div>
        <button
          className="ok-dialog-btn"
          style={{ marginRight: "10px" }}
          onClick={() => setAlreadySelectedDialog(false)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default EditPanel;
