import React from "react";
import { useState } from "react";

import { SocketNames } from "src/interface/PiqState";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import TradingViewAdvancedWidget from "./TradingViewAdvanceWidgets";

export const TradingViewComponent: React.FC<{
  socket: SocketNames;
}> = ({ socket }) => {

  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);

  const [isFetching, setFetching] = useState<boolean>(false);
  const refresh_function = () => {
    setFetching(true);
    const time = setTimeout(() => {
      setFetching(false);
    }, 1000);
    return () => clearTimeout(time);
  };

  return (
    <>
      <div className={`view-chart-div `}

      >
        <ColumnHeader
          title={"TradingView"}
          socket={socket}
          isFetching={isFetching}
          fetchFunction={() => refresh_function()}
          handleEditPanel={() => setShowEditPanel(true)}
        />
        {showEditPanel && socket ? (

          <ColumnHeaderOption
            setShowEditPanel={setShowEditPanel}
            socket={socket}
          />
        ) : (
          <>

            <TradingViewAdvancedWidget />
          </>
        )}
      </div>
    </>
  );
};
