import { useEffect, useState } from "react"
import { NewsquawkAudioChannel } from "src/interface/newsquawkAudio"
import { getNewsquawkAudio } from "src/store/PIQ/action"

export const useNewsquawkAudio = () => {
    const [channels, setChannels] = useState<NewsquawkAudioChannel[] | null>(null)

    const fetchNewsquawkAudio = async () => {
        try {
            getNewsquawkAudio().then((res: any) => {
                setChannels(res.data)
            })
        } catch (err: any) {
            console.log(err.message)
        }
    }


    return { fetchNewsquawkAudio, channels }

}