export const lists = [
  {
    heading: "Futures",
    text: "",
    subList: [
      {
        title: "Introduction to futures",
        link: "https://www.cmegroup.com/education/courses/introduction-to-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Understanding the benefits of futures",
        link: "https://www.cmegroup.com/education/courses/understanding-the-benefits-of-futures.hideHeader.hideFooter.hideSubnav.%20hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "Things to know before trading CME Group futures",
        link: "https://www.cmegroup.com/education/courses/things-to-know-before-trading-cme-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Futures vs. ETFs",
        link: "https://www.cmegroup.com/education/courses/futures-vs-etfs.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Understanding futures spreads",
        link: "https://www.cmegroup.com/education/courses/understanding-futures-spreads.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "CME Group’s Micro Futures Contracts",
    text: "",
    subList: [
      {
        title: "Micro E-mini Equity Index futures products overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-futures/micro-e-mini-equity-index-futures-products-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Hedging with Micro E-mini futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-futures/hedging-with-the-micro-e-mini-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "Managing Micro E-mini futures expiration",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-futures/managing-micro-e-mini-futures-expiration.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Get to know options on Micro E-mini futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-options/get-to-know-options-on-micro-e-mini-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Understanding listings and expirations",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-options/understanding-listings-and-expirations.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "Micro E-mini Options Strategies",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-e-mini-options/micro-e-mini-options-strategies.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "Micro Gold and Silver futures overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-gold-and-silver-futures/micro-gold-and-micro-silver-futures-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro Copper futures product overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-gold-and-silver-futures/micro-copper-futures-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "Understanding options on Micro Gold futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-gold-and-silver-futures/understanding-options-on-micro-gold-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro WTI Crude Oil futures overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-futures/micro-wti-crude-oil-futures-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Option hedging with Micro WTI Crude Oil futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-futures/option-hedging-with-micro-wti-crude-oil-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title:
          "Managing expiration of the Micro WTI Crude Oil futures contract",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-futures/managing-expiration-of-the-micro-wti-crude-oil-futures-contract.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro Henry Hub Natural Gas futures product overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-futures/micro-henry-hub-natural-gas-futures-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Understanding options on Micro WTI Crude Oil futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-options/understanding-options-on-micro-wti-crude-oil-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Basic principles of Micro WTI Crude Oil options",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-options/basic-principles-of-micro-crude-oil-options.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Understanding options on Micro Henry Hub Natural Gas futures",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-crude-options/understanding-options-on-henry-hub-natural-gas-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro Treasury Yield futures overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-rates-products/yield-futures-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro FX futures product overview",
        link: "https://www.cmegroup.com/education/courses/understanding-micro-futures-contracts-at-cme-group/micro-fx-futures/micro-fx-futures-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
    ],
  },
  {
    heading: "Trading & Analysis",
    text: "",
    subList: [
      {
        title: "Building a trade plan",
        link: "https://www.cmegroup.com/education/courses/building-a-trade-plan.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Trading and analysis",
        link: "https://www.cmegroup.com/education/courses/trading-and-analysis.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Trade and risk management",
        link: "https://www.cmegroup.com/education/courses/trade-and-risk-management.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Technical analysis",
        link: "https://www.cmegroup.com/education/courses/technical-analysis.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Using fundamental analysis when evaluating trades",
        link: "https://www.cmegroup.com/education/courses/using-fundamental-analysis-when-evaluating-trades.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "Agriculture",
    text: "",
    subList: [
      {
        title: "Hedging with grain and oilseed futures and options",
        link: "https://www.cmegroup.com/education/courses/hedging-with-grain-and-oilseed-futures-and-options.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "Energy",
    text: "",
    subList: [
      {
        title: "Introduction to Natural Gas",
        link: "https://www.cmegroup.com/education/courses/introduction-to-natural-gas.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Introduction to crude oil",
        link: "https://www.cmegroup.com/education/courses/introduction-to-crude-oil.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Basic principles of Micro WTI Crude Oil futures",
        link: "https://www.cmegroup.com/education/courses/basic-principles-of-micro-wti-crude-oil-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "Equities",
    text: "",
    subList: [
      {
        title: "Introduction to equity index products",
        link: "https://www.cmegroup.com/education/courses/introduction-to-equity-index-products.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Introduction to Equity Index Sector futures",
        link: "https://www.cmegroup.com/education/courses/introduction-to-equity-sector-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Portfolio management with equity index futures and options",
        link: "https://www.cmegroup.com/education/courses/portfolio-management-with-equity-index-futures-and-options.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Micro E-mini futures",
        link: "https://www.cmegroup.com/education/courses/micro-e-mini-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "E-mini S&P 500 product overview",
        link: "https://www.cmegroup.com/education/lessons/product-e-mini-s-and-p.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "E-mini Dow product overview",
        link: "https://www.cmegroup.com/education/lessons/e-mini-dow-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
      {
        title: "Nikkei product overview",
        link: "https://www.cmegroup.com/education/lessons/nikkei-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "FX",
    text: "",
    subList: [
      {
        title: "Introduction to FX",
        link: "https://www.cmegroup.com/education/courses/introduction-to-fx.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html",
      },
    ],
  },
  {
    heading: "Interest Rates",
    text: "",
    subList: [
      {
        title: "Introduction to treasuries",
        link: "https://www.cmegroup.com/education/courses/introduction-to-treasuries.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html? utm_source=PiQStudio&utm_medium=iframe&utm_campaign=distribution&utm_content=introductio n-to-treasuries",
      },
      {
        title: "Introduction to SOFR",
        link: "https://www.cmegroup.com/education/courses/introduction-to-sofr.hideHeader.hideFooter.%20hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio",
      },
      {
        title: "CME Institute Live: Yield futures",
        link: "https://www.cmegroup.com/education/courses/cme-institute-live-yield-futures.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?utm_source=PiQStudio&utm_medium=iframe&utm_campaign=distribution&utm_content=cme-institute-live-yield-futures",
      },
    ],
  },
  {
    heading: "Metals",
    text: "",
    subList: [
      {
        title: "Introduction to precious metals",
        link: "https://www.cmegroup.com/education/courses/introduction-to-precious-metals.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=introduction-to-precious-metals",
      },
      {
        title: "Introduction to base metals",
        link: "https://www.cmegroup.com/education/courses/introduction-to-base-metals.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=introduction-to-base-metals",
      },
      {
        title: "Gold product overview",
        link: "https://www.cmegroup.com/education/lessons/product-gold.hideHeader.hideFooter. hideSubnav.hideAddThisExt.educationIframe.html? utm_source=PiQStudio&utm_medium=iframe&utm_campaign=distribution&utm_conten t=product-gold",
      },
      {
        title: "Silver product overview",
        link: "https://www.cmegroup.com/education/lessons/silver-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=silver-product-overview",
      },
      {
        title: "Copper product overview",
        link: "https://www.cmegroup.com/education/lessons/copper-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=copper-product-overview",
      },
      {
        title: "Platinum product overview",
        link: "https://www.cmegroup.com/education/lessons/platinum-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=platinum-product-overview",
      },
      {
        title: "Palladium product overview",
        link: "https://www.cmegroup.com/education/lessons/platinum-product-overview.hideHeader.hideFooter.hideSubnav.hideAddThisExt.educationIframe.html?%20utm_source=PiQStudio&utm_conten%20t=platinum-product-overview",
      },
    ],
  },
];
