import { ArrowRightOutlined } from '@ant-design/icons';
import React from 'react'
import { useSelector } from 'react-redux';
import noBookmark from 'src/style/images/no-bookmark.svg'
import arrowRightBlue from 'src/style/images/arrow-right-blue.svg'
import { useBookmarkContext } from 'src/context/BookmarkProvider';

const BookmarkNotFound = () => {
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));

    const { setShowBookmark } = useBookmarkContext();

    return (
        <div
            className={
                `not_found_container`
            }
        >
            <div className="image">
                <img src={noBookmark} />
            </div>
            <h1>
                No articles bookmarked yet!
            </h1>
            <p>
                Go to 'Articles feed' to add articles to your bookmark article list.
            </p>
            <div className="explore" onClick={() => {
                setShowBookmark(false)
            }
            }>
                <span>
                    Explore Articles
                </span>
                <img src={arrowRightBlue} alt="" />
            </div>
        </div>
    );
}

export default BookmarkNotFound