import { postBookmarkPayload } from "src/store/PIQ/types";
import { feedAxiosInstance } from "src/store/utility";

export const getBookmarks = (page: number, pageSize: number) => {
  return feedAxiosInstance.get(`bookmarks`, {
    params: { page: page, limit: pageSize },
  });
};

export const postBookmark = (body: postBookmarkPayload) => {
  return feedAxiosInstance.post(`bookmarks`, body);
};

export const deleteBookmark = (body: { article_id: string }) => {
  return feedAxiosInstance.delete(`bookmarks/${body.article_id}`);
};
