import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { feedAxiosInstance } from "../utility";

const initialState = {
  error: "",
  loading: false,
  podcasts: [],
};

export const fetchPiqPodcasts: any = createAsyncThunk(
  "piqPodcasts",
  async () => {
    const res = await feedAxiosInstance.get("podcasts");
    return res.data;
  }
);

export const podcastSlice = createSlice({
  name: "podcasts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPiqPodcasts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPiqPodcasts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.podcasts = action.payload;
    });
    builder.addCase(fetchPiqPodcasts.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message!;
      state.podcasts = [];
    });
  },
});

export const {} = podcastSlice.actions;
export default podcastSlice.reducer;
