export const defaultFilter = {
  keyword: "",
  boolean: "AND",
};

export const firstDefaultFilter = {
  keyword: "",
  boolean: "ONLY",
};

export const tierPrivilege: Record<string, number> = {
  low: 1,
  mid: 3,
  high: 10,
};

export const hideFilterOptionsComponents = [
  "tradingview",
  "investing-economic-calender",
  "telegram",
  "cmegroup-app",
  "marketoverview",
  "forexcrossrates",
  "stockscreener",
  "electionwinnertrump",
  "electionwinnerkamala",
  "popularvotewinnertrump",
  "popularvotewinnerkamala",
  "willbidenfinishhisterm",
  "cryptocomparecryptonews",
  "cryptocompareadvancedchart",
  "cryptocompareprices",
  "economiccalendarwidget",
  "forexheatmap",
  "marketdatawidget",
  "stockheatmap",
  "tickertaperegular",
  "news",
  "singleticker",
  "yeartodatestockheatmap",
  "tickertapecompact",
  "watchlist",
];

export const filterOptions = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
  {
    label: "NOT",
    value: "NOT",
  },
];
export const firstFilterOptions = [
  {
    label: "ONLY",
    value: "ONLY",
  },
  {
    label: "NOT",
    value: "NOT",
  },
];
