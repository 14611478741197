import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { cryptoWidgetConfig } from 'src/constants/charts';

const CryptoCompareCryptoNewsWidget: React.FC<{
    reload: boolean;
    setFetching: (fetching: boolean) => void;
    setReload: Dispatch<SetStateAction<boolean>>;
}> = ({ reload, setFetching, setReload }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        const loadCryptoCompareWidget = () => {
            if (!containerRef.current) return; // Ensure containerRef is available

            const baseUrl = 'https://widgets.cryptocompare.com/';
            const appName = 'local';
            const feedUrl = `${baseUrl}serve/v1/coin/feed?fsym=BTC&tsym=USD&feedType=cryptoglobe&app=${appName}`;

            // Create a script element
            const script = document.createElement('script');
            script.src = feedUrl;
            script.type = 'text/javascript';
            script.async = true;

            // Apply the config based on dark mode

            // Append the script to the container
            containerRef.current.appendChild(script);

            // Set fetching and reload state after the script loads
            script.onload = () => {
                setFetching(false);
                setReload(false);
            };

            // Handle script loading errors
            script.onerror = () => {
                setFetching(false);
                setReload(false);
            };
        };

        // Cleanup any existing script/iframe before appending a new one
        const cleanUpExistingElements = () => {
            if (containerRef.current) {
                // Clear the container to remove old widgets
                containerRef.current.innerHTML = '';
            }
        };

        // Cleanup on mount and before each reload
        cleanUpExistingElements();
        loadCryptoCompareWidget();

        return () => {
            // Cleanup on unmount
            cleanUpExistingElements();
        };
    }, [darkMode, reload, setFetching, setReload]);

    return (
        <div
            className={`tradingview-widget-container stock-screener-widget-container ${darkMode ? 'dark' : ''}`}
            ref={containerRef}
        >
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default CryptoCompareCryptoNewsWidget;
