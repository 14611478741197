import React from "react";
import { decodeEntities, getKeywordHighlight } from "src/utils/utilFunction";
import useFeeds from "../../../Hooks/useFeeds";
import { SocketNames } from "src/interface/PiqState";
import { SingleKeywordColConfig } from "src/interface/keyword";
import ReactHtmlParser from "react-html-parser";
import { useAuthContext } from "src/context/AuthProvider";

const TitleComponent: React.FC<{
    item: any;
    socket: SocketNames;
    colConfig: SingleKeywordColConfig;
}> = ({ item, socket, colConfig }) => {

    const { isSignedIn: isUserLoggedIn } = useAuthContext();

    const { handleShowDetials } = useFeeds();
    return (
        <>
            {item?.title && (
                <div
                    className="text-primary v2-suite-column-main-title"
                    onClick={() => handleShowDetials(item, socket)}
                >
                    {/* {ReactHtmlParser(decodeTitle(getKeywordHighlight(item?.title, colConfig)))} */}
                    {
                        isUserLoggedIn ?
                            (ReactHtmlParser((decodeEntities(getKeywordHighlight(item?.title, colConfig), null)))) :
                            (ReactHtmlParser((decodeEntities(item?.title))))
                    }
                    {socket === "piqfeeds" && item?.type !== 'investingEconomicCalender' && item?.type !== 'investingEarningCalender' && <span className="piq-feed-partner-name"> {" (" + item?.type + ")"}</span>}
                </div>
            )}
        </>
    );
};
export default TitleComponent;
