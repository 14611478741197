import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { SocketNames } from "src/interface/PiqState";
import { getFormatedDate, getUrlToShare } from "src/utils/common";
import useFeeds from "../../../Hooks/useFeeds";
import { SocialMediaShare } from "../socialMediaShare";
import { useBookmarkContext } from "src/context/BookmarkProvider";

const PublishedDateComponent: React.FC<{
  item: any;
  socket: SocketNames;
  twitterAccount: string;
  showShareIcon: boolean;
}> = ({ item, socket, twitterAccount, showShareIcon }) => {
  const { handleSocialMediaShareData, handleCopyLink } = useFeeds();
  const { bookmarkClickHandler, isBookmarked } = useBookmarkContext();

  return (
    <>
      {item?.pubDate && (
        <Row gutter={10}>
          <Col span={16} className="time-label">
            {getFormatedDate(item?.pubDate)}
          </Col>
          <Col
            hidden={socket === "audiomercados" || socket === "newsquawk"}
            span={8}
            className="share-icon-div pointer"
          >
            {socket !== "newsquawk" && (
              <SocialMediaShare
                data={handleSocialMediaShareData(item, socket, twitterAccount)}
                article_id={item?._id}
                url={"\n\nFull Story → " + getUrlToShare(item, socket)}
                onCopyLink={() => handleCopyLink(item, socket)}
                showShareIcon={showShareIcon}
                bookmarkClickHandler={() => {
                  bookmarkClickHandler({ ...item, sourceName: socket })
                }}
              ></SocialMediaShare>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default PublishedDateComponent;
