import { ColFilterConfig } from "src/context/IndividualFeedProvider";
import { feedAxiosInstance } from "src/store/utility";

export const postFeedFilter = (data: ColFilterConfig) => {
  const formattedData = {
    ...data,
  };
  return feedAxiosInstance.post("/filter", formattedData);
};

export const getFeedFilter = (query: any = {}) => {
  return feedAxiosInstance.get(`/filter/${query}`);
};
