export const USER_DETAIL_START = "USER_DETAIL_START";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL";

export const USER_EDIT_START = "USER_EDIT_START";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAIL = "USER_EDIT_FAIL";

export const USER_DELETE_START = "USER_DELETE_START";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_EDIT_LAYOUT = "USER_EDIT_LAYOUT";

export const USER_TOGGLE_DARK_MODE = "USER_TOGGLE_DARK_MODE";

export const USER_LOG_OUT = "USER_LOG_OUT";

export const GET_USER_SUBSCRIPTION_DETIALS = "GET_USER_SUBSCRIPTION_DETIALS";

export const SET_SUITE_ACTIVE_WEB_COLUMN = "SET_SUITE_ACTIVE_WEB_COLUMN";

export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const SET_SIDEBAR_THEME_COLOR = "SET_SIDEBAR_THEME_COLOR";

export const VERSION_CHANGE = "VERSION_CHANGE";

export const EDIT_ARTICLE_LIST_CONFIG = "EDIT_ARTICLE_LIST_CONFIG";
