import { SocketNames } from "../interface/PiqState";
import { useDispatch } from "react-redux";
import { getFeedDetail } from "src/store/PIQ/action";
import { getUrlToShare, openNewWindow } from "src/utils/common";
import { checkIfDivHasHighlight, decodeTitle, getDivHighlightColor } from "src/utils/utilFunction";
import { message } from "antd";
import { editArticleListConfig } from "src/store/User/action";
import { limitIncreaser } from "src/utils/limitExceed";
import { useAuthContext } from "src/context/AuthProvider";
import {
  changeFeedDetailClicked,
  trueLimitExceed,
} from "src/store/LimitExceed/limitExceedSlice";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useState } from "react";
import { SingleKeywordColConfig } from "src/interface/keyword";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

const useFeeds = () => {
  const dispatch = useDispatch();
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const { piqSuiteLayout, setPiqSuiteLayout, setActiveDrawer, activeDrawerState } = useLayoutContext()
  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns;
  });

  const { sendEvent } = useTrackerContext();

  const getColumnDetail = (slug: string) => {
    const column = Columns?.find((item: any) => {
      return item?.slug === slug;
    });
    if (column) {
      return column;
    } else {
      return {
        title: "Title Not Found",
        icon: "Not Found",
        twitterAccount: "@PiQSuite",
      };
    }
  };

  const handleShowDetials = (
    item: any,
    socket: string,
    setShowBookmark?: Dispatch<SetStateAction<boolean>>
  ) => {
    const limited = limitIncreaser(isUserLoggedIn);
    if (limited) {
      dispatch(trueLimitExceed());
      return;
    }
    dispatch(changeFeedDetailClicked(true as any));
    const column = Columns?.find((item: any) => {
      return item?.slug === socket;
    });

    if (socket === "piqfeeds") {
      if (
        item?.type === "investingEconomicCalender" ||
        item?.type === "investingEarningCalender"
      ) {
        return;
      }
      dispatch(
        editArticleListConfig({
          tabKey: item?.type || socket,
          localPage: 1,
        }) as any
      );
      detailsSwitch(item?.type, item, "feed-list");
      if (setShowBookmark) setShowBookmark(false);
      return;
    }

    if (socket === "audiomercados") {
      return;
    }
    window.removeAds();
    if (column?.has_detail_page) {
      dispatch(editArticleListConfig({ tabKey: socket, localPage: 1 }) as any);
    }
    detailsSwitch(socket, item, "feed-list");
    if (setShowBookmark) setShowBookmark(false);
  };

  const detailsSwitch = (socket: string, item: any, location: "article-list" | "feed-list") => {
    const column = Columns?.find((item: any) => {
      return item?.slug === socket;
    });

    if (column?.has_detail_page) {
      setActiveDrawer("article-details-drawer");
      dispatch(
        getFeedDetail(
          item?.uniqueSlug ? item?.uniqueSlug : item?.slug,
          column
        ) as any
      );
      sendEvent({
        eventName: trackEvents.ARTICLE_VIEWER,
        payload: {
          feed: socket,
          title: item?.uniqueSlug ? item?.uniqueSlug : item?.slug,
          location
        }
      })
    } else {
      if (item?.canonicalUrl && item?.canonicalUrl !== "") {

        sendEvent({
          eventName: trackEvents.ARTICLE_POPUP,
          payload: {
            feed: socket,
            location,
            url: item?.canonicalUrl
          }
        });
        openNewWindow(item?.canonicalUrl);

      }
    }
  };

  const handleSocialMediaShareData = (
    item: any,
    socket: SocketNames,
    twitterAccount: string
  ) => {
    const is_piq_feed =
      Columns.find((col: any) => col?.slug === socket)?.appSelectorTypes ===
      "PIQ_FEED";
    const data =
      socket === "reuters"
        ? "⚠️ " +
        (item?.version !== 1 ? item?.title : item?.title?.toUpperCase())
        : (socket === "harkster" ? "💡 " + item?.originTitle + " || " : "💡 ") +
        item?.title +
        "\n\n" +
        ` - ${twitterAccount} ${is_piq_feed ? "via" : "on"} PiQSuite.com`;
    return decodeTitle(data);
  };

  const handleCopyLink = (item: any, socket: SocketNames) => {
    if (
      socket === "piqfeeds" &&
      (item?.type === "investingEconomicCalender" ||
        item?.type === "investingEarningCalender")
    ) {
      navigator.clipboard.writeText(
        window?.location?.origin || "https://piqsuite.com"
      );
      return;
    }


    navigator.clipboard.writeText(getUrlToShare(item, socket));
    message.success("Link Copied", 10);
  };


  const divHasHighligts = (
    title: string,
    colConfig: SingleKeywordColConfig
  ) => {
    let isTrue = false;

    const borderColor = getDivHighlightColor(title, colConfig);
    if (
      isUserLoggedIn &&
      checkIfDivHasHighlight(title, colConfig) &&
      borderColor
    ) {
      isTrue = true;
    }
    if (isTrue) {
      return borderColor;
    } else {
      return false;
    }
  };

  return {
    handleShowDetials,
    handleSocialMediaShareData,
    handleCopyLink,
    detailsSwitch,
    Columns,
    getColumnDetail,
    divHasHighligts
  };
};

export default useFeeds;
