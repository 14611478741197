import React from 'react'
import { openNewWindow } from 'src/utils/common'

const ImageComponent: React.FC<{ item: any }> = ({ item }) => {


    return (
        <>
            {
                item?.image &&
                <img
                    className="cursor-pointer img-bg-center"
                    src={item?.image}
                    alt="PiQSuite.com"
                    style={{
                        borderRadius: 2,
                        maxHeight: 150,
                        cursor: "pointer"
                    }}
                    width={"100%"}
                    height="auto"
                    onClick={() => item?.canonicalUrl && openNewWindow(item?.canonicalUrl)}
                />

            }


        </>
    )
}

export default ImageComponent