import { useSelector } from "react-redux";
// interface Props {
//   optionName?: string;
// }

export const useUser = () => {
  // const [SHOW_ADS, setSHOW_ADS] = useState(false);
  const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
    hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false,
  }));
  const {
    // activeDrawer,
    // windowSize,
    // userDetail,
    // piqTVs,
    // activeColums,
    // layouts,
    SHOW_ADS,
  } = useSelector((state: any) => ({
    // activeDrawer: state?.PIQReducer?.activeDrawer,
    // windowSize: state?.PIQReducer?.windowSize,
    // userDetail: state?.user?.userDetail,
    // piqTVs: state?.videoFeed?.piqvideofeed?.data,
    // activeColums: state?.user?.suiteActiveWebColumn,
    SHOW_ADS: !hasSubscriptionForAdsRemoval,
  }));

  // useEffect(() => {}, [activeDrawer]);

  return { SHOW_ADS };
};
