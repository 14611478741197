import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useLayoutContext } from "src/context/LayoutProvider";
import { activeDrawer } from "src/interface/PiqState";
import AppLayout from ".";
import AuthLayout from "src/development/Container/Web/Components/AuthLayout/AuthLayout";
import LoginPage from "../Auth/LoginPage/LoginPage";
import RegisterPage from "../Auth/RegisterPage";
import PricingPage from "../PricingPage/PricingPage";

const TCComponent = lazy(() => import("../pages/TC"));
const PPComponent = lazy(() => import("../pages/PP"));
const CCPA = lazy(() => import("../pages/ccpa"));
const YesCookies = lazy(() => import("../pages/yesCookies"));
const WebDashboard = lazy(
    () => import("src/development/Container/Web/Dashboard")
);

const PagesRoute: React.FC<{

}> = ({

}) => {
        const { setPiqSuiteLayout } = useLayoutContext()
        return (
            <Routes>
                <Route path="/" element={<AppLayout />} >
                    <Route
                        path="/"
                        element={<WebDashboard />}
                    />
                    <Route path="/ccpa" element={<CCPA />} />
                    <Route
                        path="terms-and-conditions"
                        element={<TCComponent />}
                    />
                    <Route path="/privacy-policy" element={<PPComponent />} />
                    <Route path="/yes-cookies" element={<YesCookies />} />
                    <Route path="/suite" element={<Navigate to="/" />} />
                    <Route path="/reuters" element={<Navigate to="/" />} />

                    <Route path="/suite/reuters" element={<Navigate to="/" />} />

                    <Route
                        path="/:route/:routeSlug"
                        element={
                            <WebDashboard />
                        }
                    />
                </Route>

                {/* <Route path="/login" element={<AuthLayout>
                    <LoginPage />
                </AuthLayout>} />
                <Route path="/register" element={<AuthLayout>
                    <RegisterPage />
                </AuthLayout>} />
                <Route path="/pricing" element={<PricingPage />} /> */}
            </Routes>

        );
    };

export default PagesRoute;
