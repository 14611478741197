import React from "react";
import ReactDOM from "react-dom";
import "./style/index.scss";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from "./store";
import "./config/amplify-config";
import "antd/dist/antd.css";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./context/AuthProvider";
import { PiqVersionProvider } from "./context/PiqVersionProvider";
import App from "./App";
import "react-fullscreen-loader/src/loader.css";
import "./modalDark.css";
import { DeviceInfoProvider } from "./context/DeviceInfoProvider";
import { TrackerProvider } from "./context/TrackerProvider";
import { LocationProvider } from "./context/LocationProvider";
import { LayoutProvider } from "./context/LayoutProvider";
// if (!serviceWorker.isLocalhost) {
//   console.log = () => { };
//   console.error = () => { };
//   console.debug = () => { };
// }
declare global {
  interface Window {
    InvestingChannelQueue: any[]; // 👈️ turn off type checking
    InvestingChannel: any;
    ic_page: any;
    createNewPage: any;
    removeAds: any;
    ic_infinite_scroll_ad: any;
    removeElement: any;
    TradingView: any;
    tvWidget: any;
    Barchart: any;
    rdt: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LayoutProvider>
        <LocationProvider>
          <PiqVersionProvider>
            <DeviceInfoProvider>
              <TrackerProvider>
                <AuthProvider>
                  <PersistGate persistor={persistor}>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </PersistGate>
                </AuthProvider>
              </TrackerProvider>
            </DeviceInfoProvider>
          </PiqVersionProvider>
        </LocationProvider>
      </LayoutProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
