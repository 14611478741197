// TradingViewAdvancedWidget.jsx

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

let tvScriptLoadingPromise: any;

export default function TradingViewAdvancedWidget() {
    const onLoadScriptRef: any = useRef();
    const darkMode = useSelector((state: any) => state?.user?.darkMode);
    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());


            function createWidget() {
                if (document.getElementById('tradingview_eb6a3') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        height: '100%',
                        width: '100%',
                        autosize: true,
                        isTransparent: true,
                        displayMode: "regular",
                        symbol: "NASDAQ:AAPL",
                        timezone: "Etc/UTC",
                        backgroundColor: darkMode ? "#000000" : "#ffffff",
                        toolbar_bg: darkMode ? "#000000" : "#ffffff",
                        user_id: "public_user_id",
                        overrides: {
                            "paneProperties.backgroundGradientStartColor": "#020024",
                            "paneProperties.backgroundGradientEndColor": "#4f485e",
                            "paneProperties.separatorColor": "#9598a1"
                        },

                        theme: darkMode ? "dark" : "light",
                        style: "1",
                        locale: "en",
                        enable_publishing: true,
                        gridColor: "rgba(255, 0, 0, 0.06)",
                        withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        details: true,
                        hotlist: true,

                        calendar: true,
                        show_popup_button: true,
                        popup_width: "1000",

                        popup_height: "650",
                        container_id: "tradingview_eb6a3",
                        header_settings: true,
                        save_shortcuts: true,
                    });
                }
            }

            return () => {
                onLoadScriptRef.current = null;
            }
        },

        [darkMode]
    );

    return (
        <>
            <div className='tradingview-widget-container' style={{ height: '100%' }}>
                <div id='tradingview_eb6a3' />

            </div>
            <div className="tradingview-widget-copyright" style={{ background: darkMode ? '#303742' : 'white', height: '50px', border: "0.5px solid #d9dadb", marginLeft: 4 }}>
                <a href="https://www.tradingview.com/" rel="noreferrer" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
            </div>
        </>
    );
}
