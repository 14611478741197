import React from "react";
import { useSelector } from "react-redux";
import search_notfound_light from "src/style/images/search_notfound_light.svg";
import search_notfound_dark from "src/style/images/search_notfound_dark.svg";



function NotFoundCustom() {
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));
    return (
        <div
            className={
                darkMode ? "dark_feed_without_search_container" : "feed_without_search_container"
            }
        >
            <div className="image">
                {darkMode ? (
                    <img
                        className="icon"
                        src={search_notfound_dark}
                        alt="piq logo"
                        width={24}
                        height={24}
                    />
                ) : (
                    <img
                        className="icon"
                        src={search_notfound_light}
                        alt="piq logo"
                        width={24}
                        height={24}
                    />
                )}
            </div>
            <div className="para_first" style={{
                maxWidth: "45ch"
            }}>
                "No Feeds Added"
            </div>
            <div className="para_second" style={{
                maxWidth: "47ch"
            }}>
                Add a feed to see results.

            </div>
        </div>
    );
}

export default NotFoundCustom;
