import { useEffect, useState } from "react";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";

import { getGlobaleedData } from "src/store/PIQ/action";

const useGlobalSearch = () => {
  const [searchedText, setSearchedText] = useState("");
  const [globalData, setGlobalData] = useState<any>([]);
  const [showContainer, setShowContainer] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);
  const PAGE_LIMIT = 50;

  const { sendEvent } = useTrackerContext();

  useEffect(() => {
    if (searchedText !== "") {
      setShowContainer(true);
    } else {
      setShowContainer(false);
    }
  }, [searchedText]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);

  };


  const fetchMoreData = async () => {
    setPage(page + 1);
    setHasMore(true);
    if (page + 1 <= Math.ceil(totalData / 50)) {
      getGlobaleedData(page + 1, PAGE_LIMIT, searchedText)
        .then((res: any) => {
          setGlobalData([...globalData, ...res.data.data]);
        })
        .catch((err) => {
          if (err.response.data === "NO_DATA_FOUND") {
            setGlobalData([]);
            setHasMore(false);
          }
        });
    } else {
      setHasMore(false);
    }
  };

  const handleSearch = (value: string) => {
    if (value !== "") setShowContainer(true)
    setSearchedText(value);
    setRefreshKey(Math.random());
    setPage(1);
    setIsLoading(true);
    if (value !== "") {
      sendEvent({
        eventName: trackEvents.GLOBAL_SEARCH_QUERY
      })
    }
    getGlobaleedData(1, PAGE_LIMIT, value)
      .then((res: any) => {
        setGlobalData(res?.data?.data);
        setTotalData(res.data.total);
        setIsLoading(false);
        window.createNewPage();
        if (res.data.total / 50 / 1 > 1) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err: any) => {
        if (err.response.data === "NO_DATA_FOUND") {
          setGlobalData([]);
          setTotalData(0);
          setIsLoading(false);
          setHasMore(false);
        }
        setIsLoading(false);
      });
  };

  return {
    handleFocus,
    handleBlur,
    handleSearch,
    searchedText,
    isFocused,
    showContainer,
    setShowContainer,
    totalData,
    globalData,
    isLoading,
    fetchMoreData,
    hasMore,
    refreshKey,
    setSearchedText
  };
};

export default useGlobalSearch;
