import React, { createContext } from "react";
import { useAuth } from "src/Hooks/useAuth";
import { usePiqVersionContext } from "./PiqVersionProvider";

export const AuthContext = createContext({
    user: null,
    token: "",
    isSignedIn: false,
})

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {

    const { user, token, isSignedIn } = useAuth();

    const { updateVersion } = usePiqVersionContext();

    React.useEffect(() => {
        if (isSignedIn) {
            updateVersion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    return (
        <AuthContext.Provider value={{ user, token, isSignedIn }}>
            {children}
        </AuthContext.Provider>
    )
}