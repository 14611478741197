import React from "react";
import ReactHtmlParser from "react-html-parser";
import {
  decodeEntities,
  dynamicTweetImageGrid,
  dynamicStyle,
  getKeywordHighlight,
} from "src/utils/utilFunction";
import { IPriaCard } from "src/interface/TweetCard";
import { getFormatedDate, openNewWindow } from "src/utils/common";
import { useAuthContext } from "src/context/AuthProvider";
import { limitIncreaser } from "src/utils/limitExceed";
import { trueLimitExceed } from "src/store/LimitExceed/limitExceedSlice";
import { useDispatch } from "react-redux";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

const PriaCard = (props: IPriaCard) => {
  const { rumble, handleImage, colConfig } = props;
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const dispatch = useDispatch();

  const { sendEvent } = useTrackerContext();


  let full_text: string;
  let entities: any;
  let user: any;
  let twitter_username: string;
  let extended_entities: any;
  let quoted_status =
    rumble?.quoted_status?.is_quote_status && rumble?.retweeted_status === null
      ? rumble.quoted_status
      : rumble?.retweeted_status?.quoted_status || {};
  let created_at = "";
  let retweeted_user = "";
  if (rumble?.retweeted_status !== null) {
    full_text = rumble?.retweeted_status?.result?.legacy?.full_text || "";
    entities = rumble?.retweeted_status?.result?.legacy?.entities || {};
    user = rumble?.retweeted_status?.result?.legacy?.user || {};
    created_at = rumble?.retweeted_status?.result?.legacy?.created_at || "";
    retweeted_user = rumble?.user?.legacy?.name;
    twitter_username = rumble?.user?.screen_name;

    extended_entities = {};
  } else {
    full_text = rumble?.full_text || "";
    entities = rumble?.entities || {};
    user = rumble?.user || {};
    twitter_username = rumble?.user?.screen_name;
    created_at = rumble?.created_at || "";
    retweeted_user = "";
    extended_entities = rumble?.extended_entities || {};
  }

  const tweetCardUI = ({
    full_text,
    entities,
    quoted_status,
    user,
    created_at,
    extended_entities,
    twitter_username,
    slug
  }: any) => {

    return (
      <div>
        <div
          className="time-label text-bold flex items-center capitalize "
          style={{ width: "100%" }}
        >
          {/*<i className="material-icons mr-sm">date_range</i>{" "}*/}

          {created_at ? getFormatedDate(new Date(created_at)) : ""}
        </div>
        <div className="retweeted-user">
          {retweeted_user ? `Retweeted by ${retweeted_user}` : ""}
        </div>
        <div className="twitter-user">
          <label>{user?.legacy?.name}</label>
        </div>

        {/* <img src={darkMode ? ExportIconDark : ExportIcon} alt="link" /> */}

        <div
          className="rumble-content"
          onClick={() => {
            const limited = limitIncreaser(isUserLoggedIn);
            if (limited) {
              dispatch(trueLimitExceed());
              return;
            }
            sendEvent({
              eventName: trackEvents.ARTICLE_POPUP,
              payload: {
                feed: slug,
                location: "feed-list",
                url: `https://twitter.com/${twitter_username}/status/${rumble.id_str}`
              }
            });
            openNewWindow(
              `https://twitter.com/${twitter_username}/status/${rumble.id_str}`
            );
          }}
        // style={{ color: darkMode ? "white" : "black" }}
        // dangerouslySetInnerHTML={{
        //   __html: full_text
        // }}
        >
          {/* {
            getKeywordHighlight(`
        
             🇩🇪 Germany Hesse CPI (YoY) (Oct) $EUR\n\nActual: 3.6%\nPrevious: 4.7%\n\n- https://t.co/orvmA6Wqwk
                       `)
          } */}
          {isUserLoggedIn
            ? ReactHtmlParser(
              decodeEntities(
                getKeywordHighlight(full_text, colConfig),
                rumble?.entities
              )
            )
            : ReactHtmlParser(decodeEntities(full_text, rumble?.entities))}
          {/* {full_text} */}
        </div>

        <div
          className="imageContainer"
          style={dynamicStyle(extended_entities?.media?.length)}
        >
          {entities?.media?.length ? (
            <div
              className=" my-sm pointer"
              onClick={() =>
                handleImage({
                  data: entities?.media,
                  full_text,
                  user,
                  link: `https://twitter.com/piq/status/${rumble.id_str}`,
                })
              }
            >
              <div
                onClick={() =>
                  openNewWindow(
                    `https://twitter.com/${twitter_username}/status/${rumble.id_str}`
                  )
                }
              >
                <img
                  src={entities?.media[0]?.media_url_https}
                  alt=""
                  className="v2-suite-column-card-item-img"
                />
              </div>
            </div>
          ) : null}
        </div>

        {Object.keys(quoted_status).length ? (
          <div className=" pointer">
            <div className="v2-suite-column-card-item bg-light ">
              <div className="">
                {/* <img
                    src={quoted_status?.user?.profile_image_url}
                    alt=""
                    className={`v2-suite-column-card-item-img`}
                  /> */}
                {/* <div className="time-label capitalize twitter-list-time-label">
                  {quoted_status?.created_at
                    ? getFormatedDate(new Date(quoted_status?.created_at))
                    : ""}
                </div>
                <div>
                  <label className="twitter-user">
                    {quoted_status?.user?.legacy?.name}
                  </label>
                </div> */}
              </div>

              <div className="">
                <div
                  className="rumble-content"
                  onClick={() =>
                    openNewWindow(
                      `${quoted_status?.quoted_status_permalink?.url || ""}`
                    )
                  }
                >
                  {/* {ReactHtmlParser(
                    decodeEntities(quoted_status?.full_text || "")
                  )} */}
                  <div style={{ color: "#66dbc4", textAlign: "center" }}>
                    {ReactHtmlParser(decodeEntities("Quoted Tweet Here", null))}
                  </div>
                </div>

                {quoted_status?.extended_entities?.media?.length > 1 ? (
                  <div
                    className="imageContainer"
                    style={dynamicStyle(
                      quoted_status?.extended_entities?.media?.length,
                      50
                    )}
                  >
                    {quoted_status?.extended_entities?.media?.map(
                      (item: any, i: number) => (
                        <div
                          key={i}
                          className="img-bg-center"
                          style={dynamicTweetImageGrid(
                            i,
                            item,
                            quoted_status?.extended_entities.media.length
                          )}
                          onClick={() =>
                            handleImage({
                              data: quoted_status?.extended_entities?.media,
                              i,
                              full_text: quoted_status?.full_text,
                              user: quoted_status?.user,
                              link:
                                rumble?.retweeted_status
                                  ?.quoted_status_permalink.expanded ||
                                rumble?.quoted_status_permalink.expanded ||
                                "",
                            })
                          }
                        ></div>
                      )
                    )}
                  </div>
                ) : quoted_status?.entities?.media ? (
                  <div
                    className="rumble-image my-sm pointer"
                    onClick={() =>
                      handleImage({
                        data: quoted_status?.entities?.media,
                        full_text: quoted_status?.full_text,
                        user: quoted_status?.user,
                        link:
                          rumble?.retweeted_status?.quoted_status_permalink
                            .expanded ||
                          rumble?.quoted_status_permalink.expanded ||
                          "",
                      })
                    }
                  >
                    <img
                      onClick={() =>
                        openNewWindow(
                          `https://twitter.com/${twitter_username}/status/${rumble.id_str}`
                        )
                      }
                      src={quoted_status?.entities?.media[0]?.media_url}
                      alt=""
                      className="v2-suite-column-card-item-img"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      {tweetCardUI({
        full_text,
        entities,
        quoted_status,
        user,
        created_at,
        extended_entities,
        twitter_username,
        slug: colConfig?.i
      })}
    </>
  );
};

export default PriaCard;
