import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { feedAxiosInstance } from "src/store/utility";


export const LoadMoreButton: React.FC<{
    totalPage: number,
    data: any[],
    page: number,
    dataColRef: any,
    handleColumnScroll: any,
    isFetching: boolean,
    socket: string,
    setScrollTop: any
    setPage: any,
    pageLimit: number,
    setData: any,
    setIsFetching: any,
    api: string
    isFilterOn: boolean;
    setIsSocketLoading: any
}> = ({ setIsSocketLoading, api, setIsFetching, setData, totalPage, data, page, dataColRef, handleColumnScroll, isFetching, socket, setScrollTop, setPage, pageLimit, isFilterOn }) => {

    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));

    return (
        <div
            className="loadmore-button-container"
            hidden={totalPage <= page || !data?.length || isFilterOn}
        >
            <div
                className="button"
                onClick={() => {
                    totalPage >= page &&
                        handleColumnScroll(
                            true,
                            dataColRef,
                            (bool: boolean) => setScrollTop(true),
                            page,
                            (page: number) => setPage(page),
                            (page: number) => {
                                const params = { page: page, limit: pageLimit };
                                feedAxiosInstance
                                    .get(api, { params })
                                    .then((res) => {
                                        setData([...data, ...res?.data?.data]);
                                    })
                                    .finally(() => {
                                        setIsFetching(false);
                                        setIsSocketLoading(false)
                                    });
                            },
                            (isFetching: boolean) => setIsFetching(isFetching),
                            isFetching
                        );
                }}
            >
                {isFetching || !data?.length ? (
                    <div style={{ width: "14px" }}>
                        <LoadingOutlined
                            style={{
                                fontSize: "13px",
                                color: darkMode ? "#8989a1" : "black",
                                strokeWidth: "50", // --> higher value === more thickness the filled area
                                stroke: darkMode ? "#8989a1" : "black",
                            }}
                        />
                    </div>
                ) : (
                    <div style={{ width: "70px", fontSize: "13px", marginBottom: '3px' }}>Load More</div>
                )}
            </div>
        </div>

    )
}