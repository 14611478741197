import React from "react";
import { useSelector } from "react-redux";
import search_notfound_light from "src/style/images/search_notfound_light.svg";
import search_notfound_dark from "src/style/images/search_notfound_dark.svg";



function FeedSearchNotFound({ searchedText }: {
  searchedText: string
}) {
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  return (
    <div
      className={
        darkMode ? "dark_feed_without_search_container" : "feed_without_search_container"
      }
    >
      <div className="image">
        {darkMode ? (
          <img
            className="icon"
            src={search_notfound_dark}
            alt="piq logo"
            width={24}
            height={24}
          />
        ) : (
          <img
            className="icon"
            src={search_notfound_light}
            alt="piq logo"
            width={24}
            height={24}
          />
        )}
      </div>
      <div className="para_first" style={{
        maxWidth: "45ch"
      }}>{
          searchedText ? "Well, this is awkward!" : "That’s a win! Your filter has been applied"
        }</div>
      <div className="para_second" style={{
        maxWidth: "47ch"
      }}>
        {
          searchedText ? `We couldn’t find any results for ‘${searchedText}’ in the last 14 days. Please try another
          search keyword.` : "There haven’t been any recent posts matching your filter. Posts matching your filter will start appearing here"
        }
      </div>
    </div>
  );
}

export default FeedSearchNotFound;
